import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import axios from 'axios';
import CardBody from '../../../components/Card/CardBody';
import CardHeader from '../../../components/Card/CardHeader';
import Button from '../../../components/CustomButtons/Button.js';
import CustomInput from '../../../components/CustomInput/CustomInput';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { useEffect, useState } from 'react';
import { fileToDataUri } from '../../Facilities/CreateFacility';
import {
  LetterTemplateForm,
  useStyles,
} from '../../../components/TemplateModal/TemplateModal';
import {
  API_BASE_URL,
  defaultLetterTemplate,
  PUBLIC_ASSETS_BASE_URL,
} from '../../../config';
import BusinessUnitLogoPlaceholder from '../../../assets/img/BusinessUnitLogoPlaceholder.png';
import EmailSender from './email-sender/EmailSender';

export const labelStyle = {
  fontSize: '12px',
  color: 'grey',
};
let facilityLogo;
const EmailTemplate = () => {
  const classes = useStyles();
  const [formInputs, setFormInputs] = useState({
    subject: '',
    heading: '',
    body: ``,
    logo: '',
    cc: '',
    bcc: '',
    include_cpd_logo: false,
    use_business_unit_logo: false,
    logoPreview: null,
  });
  const [preview, setPreview] = useState('');
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [radioInput, setRadioInput] = useState('otp');
  const [defaultInputValues, setDefaultInputValues] = useState(null);
  const [letterTemplate, setLetterTemplate] = useState(defaultLetterTemplate);

  const onCheckInputChange = (e) => {
    const { name } = e.target;
    if (name === 'cpdLogo') {
      return setFormInputs({
        ...formInputs,
        include_cpd_logo: !formInputs.include_cpd_logo,
      });
    }

    if (name === 'businessUnitLogo') {
      return setFormInputs({
        ...formInputs,
        use_business_unit_logo: !formInputs.use_business_unit_logo,
        logo: null,
        logoPreview: e.target.checked ? BusinessUnitLogoPlaceholder : null,
      });
    }

    setFormInputs({
      ...formInputs,
      ['logo']: name === 'facilityLogo' ? facilityLogo : '',
      logoPreview:
        name === 'facilityLogo' && PUBLIC_ASSETS_BASE_URL + facilityLogo,
      use_business_unit_logo: false,
    });
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormInputs({ ...formInputs, [name]: value });
  };

  useEffect(() => {
    const AppState = JSON.parse(localStorage['appState'] ?? '{}');
    facilityLogo = AppState?.user?.image_upload;
  }, []);

  const onPreview = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData();
      formData.append('subject', formInputs.subject);
      formData.append('heading', formInputs.heading);
      formData.append('body', formInputs.body);
      formData.append(
        'logo',
        formInputs.logo || (formInputs.logoPreview ?? ''),
      );
      formData.append('type', radioInput);
      formData.append('include_cpd_logo', formInputs.include_cpd_logo ?? false);

      const response = await axios.post(
        API_BASE_URL + '/previewEmailTemplate?type=' + radioInput,
        formData,
      );
      setIsPreviewOpen(true);
      setPreview(response.data.html);
    } catch (err) {
      console.log(err);
    }
  };

  const onLogoChange = async (e) => {
    const base64Image = await fileToDataUri(e.target.files[0]);
    setFormInputs({
      ...formInputs,
      ['logo']: base64Image,
      logoPreview: base64Image,
      use_business_unit_logo: false,
    });
  };

  const clearImage = () => {
    setFormInputs({ ...formInputs, ['logo']: '', logoPreview: null });
  };

  const onUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append('subject', formInputs.subject);
      formData.append('heading', formInputs.heading);
      formData.append('body', formInputs.body);
      if (formInputs.logo) formData.append('logo', formInputs.logo);
      formData.append('cc', formInputs.cc ?? '');
      formData.append('bcc', formInputs.bcc ?? '');
      formData.append('type', radioInput);
      formData.append('include_cpd_logo', formInputs.include_cpd_logo ?? false);
      formData.append(
        'use_business_unit_logo',
        formInputs.use_business_unit_logo ?? false,
      );
      await axios.post(API_BASE_URL + '/updateEmailTemplate/', formData);
      closePreview();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(async () => {
    const response = await axios.get(API_BASE_URL + '/getEmailTemplate/');
    setDefaultInputValues(response.data.emailTemplates);

    const logo = response.data.letterTemplate?.logo;
    setLetterTemplate({
      ...response.data?.letterTemplate,
      logo: logo,
      logoPreview: response.data.letterTemplate?.use_business_unit_logo
        ? BusinessUnitLogoPlaceholder
        : logo
        ? PUBLIC_ASSETS_BASE_URL + logo
        : null,
    });

    setFormInputs({
      ...response.data.emailTemplates[radioInput],
      ['logoPreview']: response.data.emailTemplates[radioInput]
        ?.use_business_unit_logo
        ? BusinessUnitLogoPlaceholder
        : response.data.emailTemplates[radioInput]?.logo
        ? PUBLIC_ASSETS_BASE_URL +
          response.data.emailTemplates[radioInput]?.logo
        : null,
    });
  }, []);

  useEffect(() => {
    if (defaultInputValues)
      setFormInputs({
        ...defaultInputValues[radioInput],
        logoPreview: defaultInputValues[radioInput]?.use_business_unit_logo
          ? BusinessUnitLogoPlaceholder
          : PUBLIC_ASSETS_BASE_URL + defaultInputValues[radioInput]?.logo,
      });
  }, [radioInput, defaultInputValues]);

  return (
    <div className='container' style={{ marginTop: '30px' }}>
      <Box style={{ borderRadius: '7px', background: '#fff', paddingTop: '8px', paddingBottom: '8px' }}>
        <EmailSender />
      </Box>
      <Box
        style={{ borderRadius: '7px', background: '#fff' }}
        className='makeStyles-card-96'
        noValidate
        autoComplete='off'>
        <CardHeader style={{ transform: 'translateY(-50%)' }} color='primary'>
          <RadioGroup
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
            aria-labelledby='demo-controlled-radio-buttons-group'
            name='controlled-radio-buttons-group'
            value={radioInput}
            onChange={(e) => {
              setDefaultInputValues((values) => {
                return { ...values, [radioInput]: { ...formInputs } };
              });
              setRadioInput(e.target.value);
            }}>
            <FormControlLabel
              value='otp'
              control={<Radio />}
              label='OTP Email'
              style={{ color: '#fff' }}
            />
            <FormControlLabel
              value='refund'
              control={<Radio />}
              label='Refund Email'
              style={{ color: '#fff' }}
            />
            <FormControlLabel
              value='reminder'
              control={<Radio />}
              label='Reminder Email'
              style={{ color: '#fff' }}
            />
            <FormControlLabel
              value='reminder2'
              control={<Radio />}
              label='Reminder Email 2'
              style={{ color: '#fff' }}
            />
            <FormControlLabel
              value='thankyou'
              control={<Radio />}
              label='Thank you Email'
              style={{ color: '#fff' }}
            />
            <FormControlLabel
              value='cancel'
              control={<Radio />}
              label='Cancel Email'
              style={{ color: '#fff' }}
            />
            <FormControlLabel
              value='letter'
              control={<Radio />}
              label='Letter'
              style={{ color: '#fff' }}
            />
          </RadioGroup>
        </CardHeader>
        {radioInput !== 'letter' ? (
          <CardBody style={{ marginTop: '-20px' }}>
            <h2
              style={{ fontSize: '17px', padding: '0 20px', width: '100%' }}
              className='page-heading'>
              Please consult with a CPD team member before changing the below
              values.
            </h2>
            <form onSubmit={onPreview}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}>
                    <label style={labelStyle}>Subject</label>
                    <TextField
                      variant='outlined'
                      className={classes.root}
                      multiline
                      inputProps={{
                        style: {
                          fontSize: '14px',
                          lineHeight: '1.3',
                          width: '100%',
                        },
                      }}
                      minRows={1}
                      maxRows={2}
                      value={formInputs?.subject ?? ''}
                      onChange={onInputChange}
                      name='subject'
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}>
                    <label style={labelStyle}>Heading</label>
                    <TextField
                      variant='outlined'
                      className={classes.root}
                      multiline
                      inputProps={{
                        style: {
                          fontSize: '14px',
                          lineHeight: '1.3',
                          width: '100%',
                        },
                      }}
                      minRows={1}
                      maxRows={2}
                      value={formInputs?.heading ?? ''}
                      onChange={onInputChange}
                      name='heading'
                    />
                  </div>
                </GridItem>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    padding: '20px',
                  }}>
                  <label style={labelStyle}>Body</label>
                  <TextField
                    variant='outlined'
                    className={classes.root}
                    multiline
                    inputProps={{
                      style: {
                        fontSize: '14px',
                        lineHeight: '1.3',
                        width: '100%',
                      },
                    }}
                    minRows={9}
                    maxRows={12}
                    value={formInputs?.body ?? ''}
                    onChange={onInputChange}
                    name='body'
                  />
                </div>
                <GridItem xs={12} sm={12} md={6}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}>
                    <label style={labelStyle}>
                      CC <small>(Comma separated)</small>
                    </label>
                    <TextField
                      variant='outlined'
                      className={classes.root}
                      multiline
                      inputProps={{
                        style: {
                          fontSize: '14px',
                          lineHeight: '1.3',
                          width: '100%',
                        },
                      }}
                      minRows={1}
                      maxRows={2}
                      value={formInputs?.cc ?? ''}
                      onChange={onInputChange}
                      name='cc'
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  {radioInput !== 'otp' && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}>
                      <label style={labelStyle}>
                        BCC <small>(Comma separated)</small>
                      </label>
                      <TextField
                        variant='outlined'
                        className={classes.root}
                        multiline
                        inputProps={{
                          style: {
                            fontSize: '14px',
                            lineHeight: '1.3',
                            width: '100%',
                          },
                        }}
                        minRows={1}
                        maxRows={2}
                        value={formInputs?.bcc ?? ''}
                        onChange={onInputChange}
                        name='bcc'
                      />
                    </div>
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div className='custom-upload m-b-30 m-t-27'>
                    <label htmlFor='signature'>Upload Logo</label>
                    <br />
                    <label htmlFor='signature' className='custom-upload-btn'>
                      <span>Browse</span>
                      <CustomInput
                        id='signature'
                        name='signature'
                        onChange={onLogoChange}
                        type='file'
                        value={''}
                        accept='image/*'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </label>
                  </div>
                  {
                    <div className='preview-image' width='130'>
                      {formInputs?.logoPreview && (
                        <img
                          alt={'logo'}
                          src={formInputs?.logoPreview}
                          width='130'
                        />
                      )}
                    </div>
                  }
                  {formInputs?.logoPreview ? (
                    <span
                      className='material-icons'
                      style={{ cursor: 'pointer' }}
                      onClick={clearImage}>
                      delete
                    </span>
                  ) : (
                    ''
                  )}
                  <div style={{ display: 'flex', marginBottom: '20px' }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='facilityLogo'
                            onChange={onCheckInputChange}
                            checked={
                              formInputs?.logoPreview ===
                              PUBLIC_ASSETS_BASE_URL + facilityLogo
                            }
                          />
                        }
                        label='Use existing logo'
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='cpdLogo'
                            onChange={onCheckInputChange}
                            checked={formInputs?.include_cpd_logo ?? false}
                          />
                        }
                        label='Include CPD Logo'
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='businessUnitLogo'
                            onChange={onCheckInputChange}
                            checked={
                              formInputs?.use_business_unit_logo ?? false
                            }
                          />
                        }
                        label='Use Business Unit Logo'
                      />
                    </FormGroup>
                  </div>
                </GridItem>
              </GridContainer>
              <div>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={onPreview}
                  type='submit'>
                  Preview & Save
                </Button>
              </div>
            </form>
          </CardBody>
        ) : (
          <CardBody style={{ marginTop: '-20px' }}>
            <LetterTemplateForm
              defaultValues={letterTemplate}
              isFacility={true}
              onSave={(values) => {
                setLetterTemplate(values);
              }}
            />
          </CardBody>
        )}
      </Box>
      <Modal
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={isPreviewOpen && preview !== ''}
        onClose={closePreview}>
        <Box
          style={{
            height: '90vh',
            background: '#fff',
            borderRadius: '8px',
            overflow: 'hidden',
            minWidth: '90vw',
            maxWidth: '800px',
          }}>
          <iframe
            style={{ outline: 'none', border: 'none' }}
            width='100%'
            height='90%'
            src={'data:text/html,' + preview}
          />
          <div
            style={{
              margin: 'auto',
              width: '250px',
              height: '100%',
            }}>
            <Button color='primary' onClick={onUpdate}>
              Confirm
            </Button>{' '}
            <Button color='danger' onClick={closePreview}>
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EmailTemplate;
