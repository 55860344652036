export default function CheckPermissionsComponent({
  parentKey,
  childKey = 'viewPage',
  permission = 'read',
  ignorePermissions,
  children,
}) {
  const isAllowed = checkPermission(parentKey, childKey, permission);

  return (
    <>
      {isAllowed || ignorePermissions ? (
        children
      ) : (
        <ErrorPage childKey={childKey} />
      )}
    </>
  );
}

export const checkPermission = (parentKey, childKey, permission = 'read') => {
  const userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
  const facilityId = localStorage.getItem('currentFacilityId');

  const userFacilityPermissions =
    userPermissions?.find(
      (permission) => permission.facility_id === +facilityId,
    )?.permissions?.[parentKey]?.[childKey] ?? [];

  return userFacilityPermissions.includes(permission);
};

const ErrorPage = ({ childKey }) => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '70%',
    gap: '10px',
    backgroundColor: 'white', // Grey background color
    padding: '30px',
    borderRadius: '10px',
    marginTop: '100px',
  };

  const warningIconStyle = {
    width: '50px',
    height: '50px',
    fill: 'red', // You can customize the color
  };

  const errorTextStyle = {
    fontSize: '24px',
    color: 'red',
    textAlign: 'center',
  };

  return (
    <div
      style={{
        height: '300px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
      }}>
      <div style={containerStyle}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          style={warningIconStyle}>
          <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zM12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8zM11 7h2v6h-2zm0 8h2v2h-2z' />
        </svg>
        <p style={errorTextStyle}>
          {childKey
            ? 'You are not authorized on this part of the page'
            : 'You are not authorized on this page'}
        </p>
        <p>This feature has not been enabled for you by your administrator</p>
      </div>
    </div>
  );
};
