import React, { Component } from 'react';

import ReactDOM from 'react-dom';
import { Link, Redirect } from 'react-router-dom';
import {
  API_BASE_URL,
  API_BASE_IMG_URL,
  PUBLIC_ASSETS_BASE_URL,
  bankNames,
} from '../../config';
import BusinessUnitLogoPlaceholder from 'assets/img/BusinessUnitLogoPlaceholder.png';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import axios from 'axios';
import $ from 'jquery';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import FormControl from '@material-ui/core/FormControl';
import loader from '../../assets/img/loader.svg';

import avatar from 'assets/img/faces/marc.jpg';
import TemplateModal from 'components/TemplateModal/TemplateModal';
import {
  FormControlLabel,
  FormGroup,
  FormLabel,
  Checkbox,
} from '@material-ui/core';
const md5 = require('md5');

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

export default class UpdateFacility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      address: '',
      phone: '',
      redirect: false,
      image_upload: '',
      file: null,
      city: '',
      state: '',
      zipcode: '',
      status: '',
      fileimg: '',
      achId: '',
      achCompanyName: '',
      bankName: '',
      allowAddressUpdate: false,
      achMethod: 'moov',
      tangoGroups: [],
      tangoAccounts: [],
      tango_group_id: '',
      tango_account_id: '',
      moovAccounts: [],
      moovPaymentMethods: [],
      moov_account_id: '',
      moov_payment_method_id: '',
      allowRefundAttachments: false,
      allowed_refund_types: [],
      loaded: false,
      templateModal: false,
      pdfTemplate: null,
      organization_id: '',
      organizations: [],
      outbound_pgp_public_key: '',
    };
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.autocomplete = null;
    this.handleName = this.handleName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleAddress = this.handleAddress.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleCity = this.handleCity.bind(this);
    this.handleState = this.handleState.bind(this);
    this.handleZipcode = this.handleZipcode.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
    this.handleOutboundPgpPublicKey = this.handleOutboundPgpPublicKey.bind(
      this,
    );
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  remove() {
    this.setState({ fileimg: false });
  }
  handleSubmit(event) {
    event.preventDefault();
    //this.props.dispatch(addParlor(this.state))
    this.clearForm();
  }
  handleAchId = (event) => {
    this.setState({ achId: event.target.value });
  };
  handleAchCompanyName = (event) => {
    this.setState({ achCompanyName: event.target.value });
  };
  handleStatus(event) {
    this.setState({ status: event.target.value });
  }
  handleCity(event) {
    this.setState({ city: event.target.value });
  }

  handleState(event) {
    this.setState({ state: event.target.value });
  }
  handleZipcode(event) {
    this.setState({ zipcode: event.target.value });
  }
  handleUploadFile = (event) => {
    event.preventDefault();

    if (this.state.file == event.target.files[0]) {
      this.setState({
        file: this.state.file,
        fileimg: URL.createObjectURL(this.state.file),
      });
    } else {
      this.setState({
        file: event.target.files[0],
        fileimg: URL.createObjectURL(event.target.files[0]),
      });
    }
  };
  cancelfrm = () => {
    this.setState({ redirect: true });
  };
  onPdfTemplateSave = (data) => {
    this.setState({ pdfTemplate: data });
  };
  /*** functions to set input values in state***/

  handleName(event) {
    this.setState({ name: event.target.value });
  }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }
  handleAddress(event) {
    this.setState({ address: event.target.value });
  }
  handlePhone(event) {
    this.setState({ phone: event.target.value });
  }

  handleBankName = (event) => {
    this.setState({ bankName: event.target.value });
  };

  handleAchMethod = (event) => {
    this.setState({ achMethod: event.target.value });
  };

  handleAddressUpdate = (event) => {
    this.setState({ allowAddressUpdate: event.target.value });
  };

  handleAllowRefundAttachments = (event) => {
    this.setState({ allowRefundAttachments: event.target.value });
  };

  handleAllowedRefundTypesChange = (event) => {
    const { checked, name } = event.target;
    this.setState((prevState) => {
      const allowed_refund_types = checked
        ? [...prevState.allowed_refund_types, name]
        : prevState.allowed_refund_types.filter((type) => type !== name);
      return { allowed_refund_types };
    });
  };

  handleOutboundPgpPublicKey(event) {
    this.setState({ outbound_pgp_public_key: event.target.value });
  }

  handlePlaceSelect() {
    let addressObject = this.autocomplete.getPlace();
    let address = addressObject.address_components;
    let addressdata = addressObject && addressObject.adr_address;
    let zip_code;
    let city_data;
    let state_data;
    let streetNo;
    let route;
    this.setState({ city: '', state: '', zipcode: '' });

    for (let i = 0; i < address.length; i++) {
      if (address[i] && address[i].types[0] == 'postal_code') {
        zip_code = address[i].long_name;
        this.setState({ zipcode: zip_code });
      }
      if (address[i] && address[i].types[0] == 'street_number') {
        streetNo = address[i].long_name;
      }
      if (address[i] && address[i].types[0] == 'route') {
        route = address[i].long_name;
      }
      if (
        (address[i] && address[i].types[0] == 'locality') ||
        (address[i] && address[i].types[0] == 'sublocality_level_1')
      ) {
        city_data = address[i].long_name;
        this.setState({ city: city_data });
      }
      if (address[i] && address[i].types[0] == 'administrative_area_level_1') {
        state_data = address[i].long_name;
        this.setState({ state: state_data });
      }
    }

    let requiredAddress;
    if (route && streetNo) {
      requiredAddress = streetNo + ' ' + route;
    } else if (route) {
      requiredAddress = route;
    } else if (streetNo) {
      requiredAddress = streetNo;
    }

    this.setState({ address: requiredAddress });
  }
  componentDidMount() {
    this.setState({ loaded: true });
    const google = window.google;
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      {},
    );

    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
    let slug =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id;
    let state = localStorage['appState'];
    let AppState;
    if (state) {
      AppState = JSON.parse(state);
    }

    axios.get(API_BASE_URL + '/getOrganizations').then((res) => {
      this.setState({ organizations: res.data.organizations });
    });

    axios
      .get(API_BASE_URL + '/facilities/' + slug)
      .then((facility) => {
        const facilitydata = facility && facility.data;
        axios
          .get(
            API_BASE_URL + '/listTangoAccounts/' + facilitydata.tango_group_id,
          )
          .then((res) => {
            this.setState({ tangoAccounts: res.data.accounts });
          });

        axios
          .get(
            API_BASE_URL +
              '/getMoovPaymentMethods/' +
              facilitydata.moov_account_id,
          )
          .then((res) => {
            this.setState({ moovPaymentMethods: res.data });
          });

        this.setState({
          name: facilitydata.name,
          email: facilitydata.email,
          address: facilitydata.address,
          phone: facilitydata.phone,
          image_upload: facilitydata.image_upload,
          city: facilitydata.city,
          state: facilitydata.state,
          zipcode: facilitydata.zipcode,
          status: facilitydata.status,
          achId: facilitydata.ach_id,
          achCompanyName: facilitydata.ach_company_name,
          tango_group_id: facilitydata.tango_group_id,
          tango_account_id: facilitydata.tango_account_id,
          moov_account_id: facilitydata.moov_account_id,
          moov_payment_method_id: facilitydata.moov_payment_method_id,
          organization_id: facilitydata.organization_id,
          loaded: false,
          bankName: facilitydata.bank_name,
          allowAddressUpdate: facilitydata.allow_address_update,
          achMethod: facilitydata.ach_method,
          allowRefundAttachments: facilitydata.allow_refund_attachments,
          allowed_refund_types: facilitydata.allowed_refund_types,
          outbound_pgp_public_key: facilitydata.outbound_pgp_public_key,

          pdfTemplate: {
            name: facilitydata.template_name,
            date: facilitydata.date,
            line1: facilitydata.line1,
            line2: facilitydata.line2,
            line3: facilitydata.line3,
            disclaimer: facilitydata.disclaimer,
            signature: facilitydata.signature,
            logo: facilitydata.logo,
            use_business_unit_logo: facilitydata.use_business_unit_logo,
            include_cpd_logo: facilitydata.include_cpd_logo,
            logoPreview: facilitydata?.use_business_unit_logo
              ? BusinessUnitLogoPlaceholder
              : facilitydata?.logo
              ? PUBLIC_ASSETS_BASE_URL + facilitydata?.logo
              : null,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });

    axios.get(API_BASE_URL + '/listTangoGroups').then((res) => {
      this.setState({ tangoGroups: res.data.groups });
    });

    axios.get(API_BASE_URL + '/getMoovAccounts').then((res) => {
      this.setState({ moovAccounts: res.data });
    });
  }

  /*** function to integrate api to update facility*/

  updatefacility = (e) => {
    e.preventDefault();
    let slug =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id;

    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    if (this.state.name == '') {
      alert('Please enter Facility name');
      $('#name').focus();
      return false;
    } else if (this.state.email == '') {
      alert('Please enter Facility email');
      $('#email').focus();
      return false;
    } else if (this.state.city == '') {
      alert('Please enter city');
      $('#city').focus();
    } else if (this.state.state == '') {
      alert('Please enter state');
      $('#state').focus();
    } else if (this.state.zipcode == '') {
      alert('Please enter zipcode');
      $('#phone').focus();
    } else if (this.state.address == '') {
      alert('Please enter Facility address');
      $('#address').focus();
      return false;
    } else if (this.state.phone == '') {
      alert('Please enter Facility phone');
      $('#phone').focus();
    } else if (!Number(this.state.phone)) {
      alert('Phone should be in numbers only');
      $('#phone').focus();
      return false;
    } else if (!Number(this.state.phone)) {
      alert('Phone should be in numbers only');
      $('#phone').focus();
      return false;
    } else if (this.state.phone.length > 12) {
      alert('Phone number length should not be greater than 12');
      $('#phone').focus();
      return false;
    } else if (!this.state.allowed_refund_types.length) {
      alert(
        'You need to select at least on type of refund types (Consumer Payments/Insurance)',
      );
      return false;
    } else {
      this.setState({ loaded: true });

      const formData = new FormData();
      formData.append('file', this.state.file);
      formData.append('name', this.state.name);
      formData.append('email', this.state.email);
      formData.append('address', this.state.address);
      formData.append('phone', this.state.phone);
      formData.append('city', this.state.city);
      formData.append('state', this.state.state);
      formData.append('zipcode', this.state.zipcode);
      formData.append('status', this.state.status);
      formData.append('achCompanyName', this.state.achCompanyName);
      formData.append('achId', this.state.achId);
      formData.append('tango_group_id', this.state.tango_group_id);
      formData.append('tango_account_id', this.state.tango_account_id);
      formData.append('moov_account_id', this.state.moov_account_id);
      formData.append(
        'moov_payment_method_id',
        this.state.moov_payment_method_id,
      );
      formData.append('organization_id', this.state.organization_id);
      formData.append('bank_name', this.state.bankName);
      formData.append('allow_address_update', this.state.allowAddressUpdate);
      formData.append('ach_method', this.state.achMethod);
      formData.append(
        'allow_refund_attachments',
        this.state.allowRefundAttachments,
      );
      formData.append(
        'allowed_refund_types',
        JSON.stringify(this.state.allowed_refund_types),
      );
      formData.append(
        'outbound_pgp_public_key',
        this.state.outbound_pgp_public_key,
      );

      let state = localStorage['appState'];
      let AppState;
      if (state) {
        AppState = JSON.parse(state);
      }

      let token = AppState && AppState.user && AppState.user.accessToken;

      const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      };

      axios
        .put(API_BASE_URL + '/editfacility/' + slug, formData, {
          headers: headers,
        })
        .then((response) => {
          return response;
        })
        .then((res) => {
          this.setState({ redirect: true, loaded: false });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  openModal = () => {
    this.setState({
      templateModal: true,
    });
  };
  closeModal = () => {
    this.setState({
      templateModal: false,
    });
  };

  onTangoGroupChange = (e) => {
    this.setState({ tango_group_id: e.target.value });
    axios
      .get(API_BASE_URL + '/listTangoAccounts/' + e.target.value)
      .then((res) => {
        this.setState({ tangoAccounts: res.data.accounts });
      });
  };

  onMoonAccountChange = (e) => {
    this.setState({ moov_account_id: e.target.value });

    axios
      .get(API_BASE_URL + '/getMoovPaymentMethods/' + e.target.value)
      .then((res) => {
        this.setState({ moovPaymentMethods: res.data });
      });
  };

  onMoovPaymentMethodChange = (e) => {
    this.setState({ moov_payment_method_id: e.target.value });
  };

  onTangoAccountChange = (e) => {
    this.setState({ tango_account_id: e.target.value });
  };

  onOrganizationIdChange = (e) => {
    this.setState({ organization_id: e.target.value });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to='/admin/facilities' />;
    }
    let logo_url;
    if (this.state && this.state.image_upload) {
      logo_url = API_BASE_URL + '/getFile?filePath=' + this.state.image_upload;
    }
    return (
      <div>
        <form onSubmit={this.updatefacility}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color='primary'>
                  <h4>Update Facility</h4>
                </CardHeader>
                <CardBody>
                  {this.state.loaded && (
                    <div className='loaders'>
                      <img src={loader} />
                    </div>
                  )}
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        type='text'
                        labelText='Name'
                        onChange={this.handleName}
                        value={this.state.name}
                        id='name'
                        name='name'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Email'
                        id='email'
                        name='email'
                        onChange={this.handleEmail}
                        value={this.state.email}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Phone'
                        id='phone'
                        name='phone'
                        onChange={this.handlePhone}
                        value={this.state.phone}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Address'
                        id='autocomplete'
                        name='address'
                        ref='input'
                        onChange={this.handleAddress}
                        value={this.state.address}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='City'
                        id='city'
                        name='city'
                        onChange={this.handleCity}
                        value={this.state.city}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='State'
                        id='state'
                        name='state'
                        onChange={this.handleState}
                        value={this.state.state}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Zipcode'
                        id='zipcode'
                        name='zipcode'
                        onChange={this.handleZipcode}
                        value={this.state.zipcode}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Ach Company Name'
                        id='achCompanyName'
                        name='achCompanyName'
                        onChange={this.handleAchCompanyName}
                        value={this.state.achCompanyName}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Ach Id'
                        id='achId'
                        name='achId'
                        onChange={this.handleAchId}
                        value={this.state.achId}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      {/*<!--<label>Status</label>
                  <select name="status" className="MuiInputBase-input MuiInput-input" style={{border:"1px solid lightgrey",marginTop:'20px'}} id="status" onChange={this.handleStatus} value={this.state.status}>
				  <option value="1">Active</option>
				  <option value="0">Inactive</option>
  </select>-->*/}
                      <div className='custom-select'>
                        <FormControl>
                          <InputLabel id='demo-simple-select-label'>
                            Status
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={this.state.status}
                            onChange={this.handleStatus}
                            className='full-width-elem'>
                            <MenuItem value={0}>Inactive</MenuItem>
                            <MenuItem value={1}>Active</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className='custom-select'>
                        <FormControl>
                          <InputLabel id='demo-simple-select-tango'>
                            Tango Group
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-tango'
                            value={this.state.tango_group_id}
                            onChange={this.onTangoGroupChange}
                            name='group_id'
                            className='full-width-elem'>
                            {this.state.tangoGroups.map((obj, index) => (
                              <MenuItem value={obj && obj.id}>
                                {obj && obj.group_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className='custom-select'>
                        <FormControl>
                          <InputLabel id='demo-simple-select-tango-acc'>
                            Tango Account
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-tango-acc'
                            value={this.state.tango_account_id}
                            onChange={this.onTangoAccountChange}
                            name='group_id'
                            className='full-width-elem'>
                            {this.state.tangoAccounts.map((obj, index) => (
                              <MenuItem value={obj && obj.id}>
                                {obj && obj.account_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className='custom-select'>
                        <FormControl>
                          <InputLabel id='allow-address-update'>
                            Allow Address Update
                          </InputLabel>
                          <Select
                            labelId='allow-address-update'
                            value={this.state.allowAddressUpdate}
                            onChange={this.handleAddressUpdate}
                            name='allowAddressUpdate'
                            className='full-width-elem'>
                            <MenuItem value={'true'}>Yes</MenuItem>
                            <MenuItem value={'false'}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className='custom-select'>
                        <FormControl>
                          <InputLabel id='ach-method'>ACH Method</InputLabel>
                          <Select
                            labelId='ach-method'
                            value={this.state.achMethod}
                            onChange={this.handleAchMethod}
                            name='achMethod'
                            className='full-width-elem'>
                            <MenuItem value={'moov'}>Moov</MenuItem>
                            <MenuItem value={'default'}>Default</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className='custom-select'>
                        <FormControl>
                          <InputLabel id='demo-simple-select-moov'>
                            Moov Account
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-moov'
                            value={this.state.moov_account_id}
                            onChange={this.onMoonAccountChange}
                            name='group_id'
                            className='full-width-elem'>
                            {this.state.moovAccounts.map((obj, index) => (
                              <MenuItem value={obj && obj.id}>
                                {obj && obj.account_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className='custom-select'>
                        <FormControl>
                          <InputLabel id='demo-simple-select-moov-acc'>
                            Moov Payment Methods
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-moov-acc'
                            value={this.state.moov_payment_method_id}
                            onChange={this.onMoovPaymentMethodChange}
                            name='group_id'
                            className='full-width-elem'>
                            {this.state.moovPaymentMethods.map((obj, index) => (
                              <MenuItem value={obj && obj.id}>
                                {obj && obj.holder_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className='custom-select'>
                        <FormControl>
                          <InputLabel id='bank-name'>Bank Name</InputLabel>
                          <Select
                            labelId='bank-name'
                            value={this.state.bankName}
                            onChange={this.handleBankName}
                            name='bankName'
                            className='full-width-elem'>
                            {Object.values(bankNames).map((obj, index) => (
                              <MenuItem value={obj}>{obj}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className='custom-select'>
                        <FormControl>
                          <InputLabel id='demo-simple-select-org'>
                            Organizations
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-org'
                            value={this.state.organization_id}
                            onChange={this.onOrganizationIdChange}
                            name='organization_id'
                            className='full-width-elem'>
                            {this.state.organizations.map((obj, index) => (
                              <MenuItem value={obj && obj.id}>
                                {obj && obj.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className='custom-select'>
                        <FormControl>
                          <InputLabel id='allow-refund-attachments'>
                            Allow Refund Attachments
                          </InputLabel>
                          <Select
                            labelId='allow-refund-attachments'
                            value={this.state.allowRefundAttachments}
                            onChange={this.handleAllowRefundAttachments}
                            name='allowRefundAttachments'
                            className='full-width-elem'>
                            <MenuItem value={'true'}>Yes</MenuItem>
                            <MenuItem value={'false'}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl component='fieldset'>
                        <FormLabel component='label'>
                          Allowed Refund Types
                        </FormLabel>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.allowed_refund_types.includes(
                                  'regular',
                                )}
                                onChange={this.handleAllowedRefundTypesChange}
                                name='regular'
                              />
                            }
                            label='Consumer Payments'
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.allowed_refund_types.includes(
                                  'insurance',
                                )}
                                onChange={this.handleAllowedRefundTypesChange}
                                name='insurance'
                              />
                            }
                            label='Insurance Payments'
                          />
                        </FormGroup>
                      </FormControl>
                    </GridItem>{' '}
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Outbound PGP Public Key'
                        id='outbound_pgp_public_key'
                        name='outbound_pgp_public_key'
                        onChange={this.handleOutboundPgpPublicKey}
                        value={this.state.outbound_pgp_public_key}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          multiline: true,
                          rows: 4,
                        }}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={6}
                      style={{ alignSelf: 'end' }}>
                      <Button color='primary' onClick={this.openModal}>
                        Open Template Editor
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className='custom-upload m-b-30 m-t-27'>
                        <label htmlFor='logoimg'>Upload Facility Logo</label>
                        <br />
                        <label htmlFor='logoimg' className='custom-upload-btn'>
                          <span>Browse</span>
                          <CustomInput
                            label='Upload Facility Logo'
                            id='logoimg'
                            name='logoimg'
                            onChange={this.handleUploadFile}
                            type='file'
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </label>
                      </div>
                      {this.state.image_upload && !this.state.fileimg ? (
                        <div className='preview-image'>
                          <img src={logo_url} width='130' />
                        </div>
                      ) : (
                        ''
                      )}
                      {this.state.fileimg ? (
                        <div className='preview-image'>
                          <img
                            src={this.state && this.state.fileimg}
                            width='130'
                          />
                        </div>
                      ) : (
                        ''
                      )}
                      {this.state && this.state.fileimg ? (
                        <span
                          className='material-icons'
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.remove()}>
                          delete
                        </span>
                      ) : (
                        ''
                      )}
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <Button color='primary' type='submit'>
                        Update
                      </Button>
                      <Button
                        color='primary'
                        onClick={this.cancelfrm}
                        className='btn-cancel'
                        style={{ marginLeft: '5px' }}>
                        Cancel
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}></GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </form>
        {this.state.pdfTemplate && (
          <TemplateModal
            defaultValues={this.state.pdfTemplate}
            facilityId={this.props.match.params.id}
            templateModal={this.state.templateModal}
            closeModal={this.closeModal}
          />
        )}
      </div>
    );
  }
}
