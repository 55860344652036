import React from 'react';
import { Modal, Box, TextField } from '@mui/material';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { modalStyle } from 'views/Clients/Claim';
import { labelStyle } from '../Managefile/email-templates/EmailTemplate';
import { openPdf } from './UpdateRefundModal';
import { Close, PictureAsPdf } from '@material-ui/icons';
import { API_BASE_URL } from 'config';
import FileList from './FileList';

const RefundInfoHistory = ({
  selectedForTrackChangesId,
  setSelectedForTrackChangesId,
  infoHistory,
  getClass,
}) => {
  return (
    <Modal
      open={selectedForTrackChangesId ? true : false}
      onClose={() => {
        setSelectedForTrackChangesId(null);
      }}>
      <Box
        sx={modalStyle}
        className='makeStyles-card-96'
        noValidate
        autoComplete='off'>
        <CardHeader style={{ transform: 'translateY(-50%)' }} color='primary'>
          <h4>Customer Information Update History</h4>
        </CardHeader>
        <CardBody style={{ marginTop: '-40px', overflowY: 'scroll' }}>
          {infoHistory.map((info, index) => {
            if (info.type === 'refund_attachments') {
              const { filesAdded, filesRemoved } = info;

              return (
                <GridContainer>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}>
                    {!!filesAdded?.length && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          padding: '20px',
                          gap: '10px',
                        }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '10px 0',
                            backgroundColor: 'rgba(0, 0, 0, 0.08)',
                            borderRadius: '5px',
                            padding: '10px',
                          }}>
                          <p style={{ fontSize: '14px' }}>
                            <b>Files Added By :</b> {info.updated_by}
                          </p>
                          <p style={{ fontSize: '12px', marginTop: '-5px' }}>
                            <b>Comment : </b> {info.comment ?? 'N/A'}
                          </p>
                        </div>
                        <FileList files={filesAdded} />
                      </div>
                    )}
                    {!!filesRemoved?.length && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          padding: '20px',
                          gap: '10px',
                        }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '10px 0',
                            backgroundColor: 'rgba(0, 0, 0, 0.08)',
                            borderRadius: '5px',
                            padding: '10px',
                          }}>
                          <p style={{ fontSize: '14px' }}>
                            <b>Files Removed By :</b> {info.updated_by}
                          </p>
                          <p style={{ fontSize: '12px', marginTop: '-5px' }}>
                            <b>Comment : </b> {info.comment ?? 'N/A'}
                          </p>
                        </div>
                        <FileList files={filesRemoved} />
                      </div>
                    )}
                  </div>
                </GridContainer>
              );
            }

            return (
              <GridContainer>
                <GridItem style={{ marginTop: '20px' }} xs={12} sm={12} md={12}>
                  {index === 0 && (
                    <h5
                      style={{
                        background: '#3AB795',
                        padding: '5px',
                        borderRadius: '5px',
                        display: 'inline',
                        color: '#fff',
                        width: 'fit-content',
                        fontSize: '14px',
                        margin: 0,
                      }}>
                      Latest
                    </h5>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '10px 0',
                      backgroundColor: 'rgba(0, 0, 0, 0.08)',
                      borderRadius: '5px',
                      padding: '10px',
                    }}>
                    <p style={{ fontSize: '14px' }}>
                      <b>Updated By :</b> {info.updated_by}
                    </p>
                    <p style={{ fontSize: '12px', marginTop: '-5px' }}>
                      <b>Comment : </b> {info.comment ?? 'N/A'}
                    </p>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}>
                    <label style={labelStyle}>First Name</label>

                    <TextField
                      variant='outlined'
                      className={getClass(info, index, 'first_name')}
                      multiline
                      inputProps={{
                        style: {
                          fontSize: '14px',
                          lineHeight: '1.3',
                          width: '100%',
                        },
                      }}
                      minRows={1}
                      maxRows={2}
                      value={info.first_name}
                      onChange={() => null}
                      name='cc'
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}>
                    <label style={labelStyle}>Last Name</label>
                    <TextField
                      variant='outlined'
                      id='component-error'
                      className={getClass(info, index, 'last_name')}
                      // color='danger'
                      multiline
                      inputProps={{
                        style: {
                          fontSize: '14px',
                          lineHeight: '1.3',
                          width: '100%',
                        },
                      }}
                      minRows={1}
                      maxRows={2}
                      value={info.last_name}
                      onChange={() => null}
                      name='cc'
                    />
                  </div>
                </GridItem>
                <GridItem style={{ marginTop: '20px' }} xs={12} sm={12} md={12}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}>
                    <label style={labelStyle}>Email</label>
                    <TextField
                      variant='outlined'
                      className={getClass(info, index, 'email')}
                      multiline
                      inputProps={{
                        style: {
                          fontSize: '14px',
                          lineHeight: '1.3',
                          width: '100%',
                        },
                      }}
                      minRows={1}
                      maxRows={1}
                      value={info.email}
                      onChange={() => null}
                      name='cc'
                    />
                  </div>
                </GridItem>
                <GridItem style={{ marginTop: '20px' }} xs={12} sm={12} md={12}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}>
                    <label style={labelStyle}>Memo</label>
                    <TextField
                      variant='outlined'
                      className={getClass(info, index, 'memo')}
                      multiline
                      inputProps={{
                        style: {
                          fontSize: '14px',
                          lineHeight: '1.3',
                          width: '100%',
                        },
                      }}
                      minRows={1}
                      maxRows={1}
                      value={info.memo}
                      onChange={() => null}
                      name='cc'
                    />
                  </div>
                </GridItem>
                <GridItem style={{ marginTop: '20px' }} xs={12} sm={12} md={12}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}>
                    <label style={labelStyle}>Business Payee Name</label>
                    <TextField
                      variant='outlined'
                      className={getClass(info, index, 'business_payee_name')}
                      multiline
                      inputProps={{
                        style: {
                          fontSize: '14px',
                          lineHeight: '1.3',
                          width: '100%',
                        },
                      }}
                      minRows={1}
                      maxRows={1}
                      value={info.business_payee_name}
                      onChange={() => null}
                      name='cc'
                    />
                  </div>
                </GridItem>

                <GridItem style={{ marginTop: '20px' }} xs={12} sm={12} md={12}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}>
                    <label style={labelStyle}>Address</label>
                    <TextField
                      variant='outlined'
                      className={getClass(info, index, 'address')}
                      multiline
                      inputProps={{
                        style: {
                          fontSize: '14px',
                          lineHeight: '1.3',
                          width: '100%',
                        },
                      }}
                      minRows={4}
                      maxRows={6}
                      value={info.address}
                      onChange={() => null}
                      name='cc'
                    />
                  </div>
                </GridItem>
              </GridContainer>
            );
          })}
        </CardBody>
      </Box>
    </Modal>
  );
};

export default RefundInfoHistory;
