import React, { Component } from 'react';

import ReactDOM from 'react-dom';
import { Link, Redirect } from 'react-router-dom';
import { API_BASE_URL, API_BASE_IMG_URL } from '../../config';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import axios from 'axios';
import $ from 'jquery';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import loader from '../../assets/img/loader.svg';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import avatar from 'assets/img/faces/marc.jpg';
import { getFacilities } from 'views/Facilities/Facilities';
const md5 = require('md5');

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

//const useStyles = makeStyles(styles);

//const classes = useStyles();

export default class CreateSubfacility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      address: '',
      message: '',
      phone: '',
      redirect: false,
      file: null,
      signatureImg: '',
      signature: null,
      city: '',
      state: '',
      zipcode: '',
      fileimg: '',
      achId: '',
      achCompanyName: '',
      loaded: false,
      facilities: [{}],
      tangoGroups: [],
      tangoAccounts: [],
      tango_group_id: '',
      tango_account_id: '',
      moovAccounts: [],
      moovPaymentMethods: [],
      moov_account_id: '',
      moov_payment_method_id: '',
      parentfacility: '',
      aptno: '',
      bankAcc: '',
      routingNo: '',
      bankAccAlias: '',
    };
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.autocomplete = null;
    this.handleEmail = this.handleEmail.bind(this);
    this.handleAddress = this.handleAddress.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handleCity = this.handleCity.bind(this);
    this.handleState = this.handleState.bind(this);
    this.handleZipcode = this.handleZipcode.bind(this);
    this.handleFacility = this.handleFacility.bind(this);
    this.handleAptNo = this.handleAptNo.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleFacility(event) {
    this.setState({ parentfacility: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    //this.props.dispatch(addParlor(this.state))
    this.clearForm();
  }
  handleAptNo(event) {
    this.setState({ aptno: event.target.value });
  }

  /*handlePlaceSelect() {
    let addressObject = this.autocomplete.getPlace();
    console.log(addressObject);
    let address = addressObject.address_components;
    let addressdata = addressObject && addressObject.adr_address;
    let zip_code;
    let city_data;
    let state_data;
    let streetNo;
    let route;
    this.setState({ city: "", state: "", zipcode: "" });

    for (let i = 0; i < address.length; i++) {
      
      if (address[i] && address[i].types[0] == "postal_code") {
        zip_code = address[i].long_name;
        this.setState({ zipcode: zip_code });
      }
      if (address[i] && address[i].types[0] == "street_number") {
        streetNo = address[i].long_name;
      }
      if (address[i] && address[i].types[0] == "route") {
        route = address[i].long_name;
      }
      if (address[i] && address[i].types[0] == "locality") {
        city_data = address[i].long_name;
        this.setState({ city: city_data });
      }
      if (address[i] && address[i].types[0] == "administrative_area_level_1") {
        state_data = address[i].long_name;
        this.setState({ state: state_data });
      }
    }
    let requiredAddress = streetNo + " " + route;
    this.setState({ address: requiredAddress });
  }*/

  handlePlaceSelect() {
    let addressObject = this.autocomplete.getPlace();
    console.log(addressObject);
    let address = addressObject.address_components;
    console.log('addressaddressaddress', address);
    let addressdata = addressObject && addressObject.adr_address;
    let zip_code;
    let city_data;
    let state_data;
    let streetNo;
    let route;
    this.setState({ city: '', state: '', zipcode: '' });

    for (let i = 0; i < address.length; i++) {
      //console.log("objectdataff",address[i])
      if (address[i] && address[i].types[0] == 'postal_code') {
        zip_code = address[i].long_name;
        this.setState({ zipcode: zip_code });
      }
      if (address[i] && address[i].types[0] == 'street_number') {
        streetNo = address[i].long_name;
      }
      if (address[i] && address[i].types[0] == 'route') {
        route = address[i].long_name;
      }
      if (
        (address[i] && address[i].types[0] == 'locality') ||
        (address[i] && address[i].types[0] == 'sublocality_level_1')
      ) {
        city_data = address[i].long_name;
        this.setState({ city: city_data });
      }
      if (address[i] && address[i].types[0] == 'administrative_area_level_1') {
        state_data = address[i].long_name;
        this.setState({ state: state_data });
      }
    }

    let requiredAddress;
    if (route && streetNo) {
      requiredAddress = streetNo + ' ' + route;
    } else if (route) {
      requiredAddress = route;
    } else if (streetNo) {
      requiredAddress = streetNo;
    }

    this.setState({ address: requiredAddress });
  }

  handleChange = (address) => {
    this.setState({ address });
  };
  /*** functions to set input values in state***/

  handleName(event) {
    this.setState({ name: event.target.value });
  }
  handleEmail(event) {
    this.setState({ email: event.target.value });
  }
  handleAddress(event) {
    this.setState({ address: event.target.value });
  }
  handlePhone(event) {
    this.setState({ phone: event.target.value });
  }

  handleCity(event) {
    this.setState({ city: event.target.value });
  }

  handleState(event) {
    this.setState({ state: event.target.value });
  }
  handleZipcode(event) {
    this.setState({ zipcode: event.target.value });
  }
  handleBankAcc = (event) => {
    this.setState({ bankAcc: event.target.value });
  };
  handleRoutingNo = (event) => {
    this.setState({ routingNo: event.target.value });
  };
  handleBankAccAlias = (event) => {
    const alphaNumericRegex = /^[a-zA-Z0-9]*$/;
    if (alphaNumericRegex.test(event.target.value))
      return this.setState({ bankAccAlias: event.target.value });
  };
  handleUploadFile = (event) => {
    this.setState({
      file: event.target.files[0],
      fileimg: URL.createObjectURL(event.target.files[0]),
    });
  };
  handleSignatureFile = (event) => {
    this.setState({
      signature: event.target.files[0],
      signatureImg: URL.createObjectURL(event.target.files[0]),
    });
  };
  handleAchId = (event) => {
    this.setState({ achId: event.target.value });
  };
  handleAchCompanyName = (event) => {
    this.setState({ achCompanyName: event.target.value });
  };
  remove() {
    this.setState({ fileimg: '' });
  }
  removeSignature() {
    this.setState({ signatureImg: '' });
  }
  cancelfrm = () => {
    this.setState({ redirect: true });
  };
  /*** function to integrate api to create facility*/
  //   componentDidMount() {
  //     const google = window.google;
  //     this.autocomplete = new google.maps.places.Autocomplete(
  //       document.getElementById("autocomplete"),
  //       {}
  //     );

  //     this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
  //   }

  createFacility = async () => {
    /*var formData = new FormData();		
    formData.append("name", this.state.name);		
    formData.append("email", this.state.email);	
    formData.append("address", this.state.address);
    formData.append("phone", this.state.phone);*/

    var minNumberofChars = 8;
    var maxNumberofChars = 25;
    var regularExpression = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    if (this.state.name == '') {
      alert('Please enter Facility name');
      $('#name').focus();
      return false;
    } else if (this.state.email == '') {
      alert('Please enter Facility email');
      $('#email').focus();
      return false;
    } else if (this.state.parentfacility == '') {
      alert('Please select parent facility.');
      $('#parentfacility').focus();
    } else if (this.state.phone == '') {
      alert('Please enter Facility phone');
      $('#phone').focus();
    } else if (this.state.city == '') {
      alert('Please enter city');
      $('#city').focus();
    } else if (this.state.state == '') {
      alert('Please enter state');
      $('#state').focus();
    } else if (this.state.zipcode == '') {
      alert('Please enter zipcode');
      $('#phone').focus();
    } else if (this.state.address == '') {
      alert('Please enter Facility address');
      $('#address').focus();
      return false;
    } else if (!Number(this.state.phone)) {
      alert('Phone should be in numbers only');
      $('#phone').focus();
      return false;
    } else if (!Number(this.state.phone)) {
      alert('Phone should be in numbers only');
      $('#phone').focus();
      return false;
    } else if (this.state.phone.length > 12) {
      alert('Phone number length should not be greater than 12');
      $('#phone').focus();
      return false;
    } else if (!this.state.bankAcc) {
      alert('Bank Account can not be empty');
      $('#bankAcc').focus();
    } else if (!this.state.routingNo) {
      alert('Routing number can not be empty');
      $('#routingNo').focus();
    } else if (!this.state.bankAccAlias) {
      alert('Bank Account alias can not be empty');
      $('#bankAccAlias').focus();
    } else if (
      !this.state.moov_account_id ||
      !this.state.moov_payment_method_id
    ) {
      alert('Please select moov account and payment method');
      return false;
    } else {
      this.setState({ loaded: true });
      const formData = new FormData();
      formData.append('file', this.state.file);
      formData.append('name', this.state.name);
      formData.append('email', this.state.email);
      formData.append('address', this.state.address);
      formData.append('phone', this.state.phone);
      formData.append('city', this.state.city);
      formData.append('state', this.state.state);
      formData.append('zipcode', this.state.zipcode);
      formData.append('parent_id', this.state.parentfacility);
      formData.append('bankAcc', this.state.bankAcc);
      formData.append('routingNo', this.state.routingNo);
      formData.append('achCompanyName', this.state.achCompanyName);
      formData.append('achId', this.state.achId);
      formData.append('tango_group_id', this.state.tango_group_id);
      formData.append('tango_account_id', this.state.tango_account_id);
      formData.append('bankAccAlias', this.state.bankAccAlias);
      formData.append('moov_account_id', this.state.moov_account_id);
      formData.append(
        'moov_payment_method_id',
        this.state.moov_payment_method_id,
      );
      if (this.state.signature) {
        const fileToDataUri = (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
              resolve(event.target.result);
            };
            reader.readAsDataURL(file);
          });
        console.log(await fileToDataUri(this.state.signature));
        formData.append(
          'signatureImg',
          await fileToDataUri(this.state.signature),
        );
      }

      let state = localStorage['appState'];
      let AppState;
      if (state) {
        AppState = JSON.parse(state);
      }

      let token = AppState && AppState.user && AppState.user.accessToken;

      const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      };

      axios
        .post(API_BASE_URL + '/create_facility', formData, { headers: headers })
        .then((response) => {
          return response;
        })
        .then((res) => {
          if (res && res.data && res.data.error == null) {
            this.setState({ redirect: true, loaded: false });
          } else {
            alert(res && res.data && res.data.msg);
            this.setState({ loaded: false });
          }
        })
        .catch((err) => {
          console.log(err?.response?.data);
          this.setState({ loaded: false, message: err?.response?.data?.msg });
        });
    }
  };

  componentDidMount() {
    this.setState({ loaded: true });
    axios.get(API_BASE_URL + '/listTangoGroups').then((res) => {
      this.setState({ tangoGroups: res.data.groups });
    });
    axios.get(API_BASE_URL + '/getMoovAccounts').then((res) => {
      console.log('moovAccounts', res.data);
      this.setState({ moovAccounts: res.data });
    });

    let state = localStorage['appState'];
    let AppState;
    if (state) {
      AppState = JSON.parse(state);
      this.setState({ isLoggedIn: AppState.isLoggedIn, user: AppState.user });
    }

    let token = AppState && AppState.user && AppState.user.accessToken;

    if (AppState && AppState.user && AppState.user.role != 'admin') {
      window.location.href = '/client/error';
    }
    getFacilities({ facility: true })
      .then((facility) => {
        //console.log("facilities",facility && facility.data)

        if (facility && facility.data && facility.data.errmsg) {
          this.setState({
            errmsg: facility && facility.data && facility.data.errmsg,
            loaded: false,
          });
        } else {
          this.setState({
            facilities: facility && facility.data,
            loaded: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    const google = window.google;
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      {},
    );

    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }
  onTangoGroupChange = (e) => {
    this.setState({ tango_group_id: e.target.value });
    axios
      .get(API_BASE_URL + '/listTangoAccounts/' + e.target.value)
      .then((res) => {
        this.setState({ tangoAccounts: res.data.accounts });
      });
  };

  onTangoAccountChange = (e) => {
    this.setState({ tango_account_id: e.target.value });
  };

  onMoonAccountChange = (e) => {
    this.setState({ moov_account_id: e.target.value });

    axios
      .get(API_BASE_URL + '/getMoovPaymentMethods/' + e.target.value)
      .then((res) => {
        this.setState({ moovPaymentMethods: res.data });
      });
  };

  onMoovPaymentMethodChange = (e) => {
    this.setState({ moov_payment_method_id: e.target.value });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to='/admin/subfacilities' />;
    }
    console.log('uploadimg', this.state.uploadimg);
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color='primary'>
                <h4>Create Sub Facility</h4>
              </CardHeader>
              <CardBody>
                {this.state.loaded && (
                  <div className='loaders'>
                    <img src={loader} />
                  </div>
                )}

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      type='text'
                      labelText='Name'
                      onChange={this.handleName}
                      value={this.state.name}
                      id='name'
                      name='name'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Email'
                      id='email'
                      name='email'
                      autoComplete='off'
                      onChange={this.handleEmail}
                      value={this.state.email}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className='custom-select'>
                      <FormControl>
                        <InputLabel id='demo-simple-select-label'>
                          Parent Facility
                        </InputLabel>
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          value={this.state.parentfacility}
                          onChange={this.handleFacility}
                          className='full-width-elem'>
                          {this.state.facilities &&
                            this.state.facilities.length > 0 &&
                            this.state.facilities.map((obj, index) => (
                              <MenuItem value={obj && obj.id}>
                                {obj && obj.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Phone'
                      id='phone'
                      name='phone'
                      onChange={this.handlePhone}
                      value={this.state.phone}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Address'
                      id='autocomplete'
                      name='address'
                      ref='input'
                      onChange={this.handleAddress}
                      value={this.state.address}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Apt/Unit No.'
                      id='aptno'
                      name='aptno'
                      onChange={this.handleAptNo}
                      value={this.state.aptno}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='City'
                      id='city'
                      name='city'
                      onChange={this.handleCity}
                      value={this.state.city}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='State'
                      id='state'
                      name='state'
                      onChange={this.handleState}
                      value={this.state.state}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Zipcode'
                      id='zipcode'
                      name='zipcode'
                      onChange={this.handleZipcode}
                      value={this.state.zipcode}
                      autoComplete='off'
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className='custom-select'>
                      <FormControl>
                        <InputLabel id='demo-simple-select-tango'>
                          Tango Group
                        </InputLabel>
                        <Select
                          labelId='demo-simple-select-tango'
                          value={this.state.tango_group_id}
                          onChange={this.onTangoGroupChange}
                          name='group_id'
                          className='full-width-elem'>
                          {this.state.tangoGroups.map((obj, index) => (
                            <MenuItem value={obj && obj.id}>
                              {obj && obj.group_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className='custom-select'>
                      <FormControl>
                        <InputLabel id='demo-simple-select-tango-acc'>
                          Tango Account
                        </InputLabel>
                        <Select
                          labelId='demo-simple-select-tango-acc'
                          value={this.state.tango_account_id}
                          onChange={this.onTangoAccountChange}
                          name='group_id'
                          className='full-width-elem'>
                          {this.state.tangoAccounts.map((obj, index) => (
                            <MenuItem value={obj && obj.id}>
                              {obj && obj.account_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className='custom-select'>
                      <FormControl>
                        <InputLabel id='demo-simple-select-moov'>
                          Moov Account
                        </InputLabel>
                        <Select
                          labelId='demo-simple-select-moov'
                          value={this.state.moov_account_id}
                          onChange={this.onMoonAccountChange}
                          name='group_id'
                          className='full-width-elem'>
                          {this.state.moovAccounts.map((obj, index) => (
                            <MenuItem value={obj && obj.id}>
                              {obj && obj.account_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className='custom-select'>
                      <FormControl>
                        <InputLabel id='demo-simple-select-moov-acc'>
                          Moov Payment Methods
                        </InputLabel>
                        <Select
                          labelId='demo-simple-select-moov-acc'
                          value={this.state.moov_payment_method_id}
                          onChange={this.onMoovPaymentMethodChange}
                          name='group_id'
                          className='full-width-elem'>
                          {this.state.moovPaymentMethods.map((obj, index) => (
                            <MenuItem value={obj && obj.id}>
                              {obj && obj.holder_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Ach Facility Name'
                      id='achCompanyName'
                      name='achCompanyName'
                      onChange={this.handleAchCompanyName}
                      value={this.state.achCompanyName}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Ach Id'
                      id='achId'
                      name='achId'
                      onChange={this.handleAchId}
                      value={this.state.achId}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Bank Account'
                      id='bankAcc'
                      name='bankAcc'
                      onChange={this.handleBankAcc}
                      value={this.state.bankAcc}
                      type='number'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Routing No'
                      id='routingNo'
                      name='routingNo'
                      onChange={this.handleRoutingNo}
                      value={this.state.routingNo}
                      type='number'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Bank Acc Alias'
                      id='bankAccAlias'
                      name='bankAccAlias'
                      onChange={this.handleBankAccAlias}
                      value={this.state.bankAccAlias}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <div className='custom-upload m-b-30 m-t-27'>
                      <label htmlFor='logoimg'>Upload Facility Logo</label>
                      <br />
                      <label htmlFor='logoimg' className='custom-upload-btn'>
                        <span>Browse</span>
                        <CustomInput
                          label='Upload Facility Logo'
                          id='logoimg'
                          name='logoimg'
                          onChange={this.handleUploadFile}
                          type='file'
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </label>
                    </div>
                    {this.state && this.state.fileimg ? (
                      <div className='preview-image' width='130'>
                        <img
                          src={this.state && this.state.fileimg}
                          width='130'
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.state && this.state.fileimg ? (
                      <span
                        className='material-icons'
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.remove()}>
                        delete
                      </span>
                    ) : (
                      ''
                    )}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <div className='custom-upload m-b-30 m-t-27'>
                      <label htmlFor='signature'>Upload Signature</label>
                      <br />
                      <label htmlFor='signature' className='custom-upload-btn'>
                        <span>Browse</span>
                        <CustomInput
                          label='Upload Signature'
                          id='signature'
                          name='signature'
                          onChange={this.handleSignatureFile}
                          type='file'
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </label>
                    </div>
                    {this.state && this.state.signatureImg ? (
                      <div className='preview-image' width='130'>
                        <img
                          src={this.state && this.state.signatureImg}
                          width='130'
                        />
                      </div>
                    ) : (
                      ''
                    )}

                    {this.state && this.state.signatureImg ? (
                      <span
                        className='material-icons'
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.removeSignature()}>
                        delete
                      </span>
                    ) : (
                      ''
                    )}
                  </GridItem>
                </GridContainer>
                {this.state.message && (
                  <GridContainer>
                    <GridItem xs={8} sm={8} md={6}>
                      <p style={{ color: 'red', padding: '10px 0' }}>
                        {this.state.message}
                      </p>
                    </GridItem>
                  </GridContainer>
                )}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Button color='primary' onClick={this.createFacility}>
                      Create
                    </Button>
                    <Button
                      color='primary'
                      onClick={this.cancelfrm}
                      className='btn-cancel'
                      style={{ marginLeft: '5px' }}>
                      Cancel
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}></GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
