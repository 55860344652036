import { useState, useEffect } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { API_BASE_URL } from '../../config';
import axios from 'axios';

import {
  Box,
  Divider,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import { modalStyle } from 'views/Clients/Claim';
import loader from '../../assets/img/loader.svg';
import { TablePaginationActions } from './common';

export const labelStyle = {
  fontSize: '12px',
  color: 'grey',
};

const UpdateOrganization = () => {
  const [redirect, setRedirect] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [name, setName] = useState('');
  const [giftcards, setGiftcards] = useState('');
  const [donations, setDonations] = useState('');
  const [domains, setDomains] = useState([]);
  const [organizationId, setOrganizationId] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [domainName, setDomainName] = useState('');
  const [modalError, setModalError] = useState('');
  const [modalDomainInfoOpen, setModalDomainInfoOpen] = useState(false);
  const [modalDomainName, setModalDomainName] = useState('');
  const [modalDomainInfo, setModalDomainInfo] = useState({});
  const [message, setMessage] = useState('');

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setOrganizationId(id);
        await getOrganizationData(id);
        await getOrganizationDomains(id);
        const state = localStorage['appState'];

        if (state) {
          const AppState = JSON.parse(state);
          if (AppState?.user?.role !== 'admin') {
            window.location.href = '/client/error';
          }
        }
      } catch (error) {}
    };

    fetchData();
  }, [id]);

  const getOrganizationData = async (orgId) => {
    try {
      const res = await axios.get(`${API_BASE_URL}/getOrganization/${orgId}`);
      setName(res.data.organization.name);
      setGiftcards(res.data.organization.tango_giftcards_uids.join(','));
      setDonations(res.data.organization.tango_donations_uids.join(','));
    } catch (error) {}
  };

  const getOrganizationDomains = async (orgId) => {
    try {
      const res = await axios.get(
        `${API_BASE_URL}/admin/getOrganizationDomains/${orgId}`,
      );
      setDomains(res.data.domains);
    } catch (error) {}
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateOrganization = async (e) => {
    e.preventDefault();
    if (!name) {
      alert('Please enter Organization name');
      return;
    }

    setLoaded(true);
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('tango_giftcards_uids', giftcards);
      formData.append('tango_donations_uids', donations);
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      const res = await axios.put(
        `${API_BASE_URL}/admin/organization/${id}`,
        formData,
        {
          headers,
        },
      );
      if (!res.data?.err) {
        setRedirect(true);
      } else {
        setMessage(res.data?.msg);
        alert(res.data?.msg);
      }
    } catch (err) {
      setMessage(err.response?.data?.msg || 'An error occurred');
    } finally {
      setLoaded(false);
    }
  };

  const openDomainInfoModal = async (domain) => {
    try {
      setModalDomainName(domain);
      const res = await axios.get(
        `${API_BASE_URL}/admin/getCustomDomainInfo/${domain}`,
      );
      setModalDomainInfoOpen(true);
      setModalDomainInfo(res.data.domainInfo);
    } catch (err) {}
  };

  const addDomain = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_BASE_URL}/admin/addOrganizationDomain`, {
        organizationId,
        domain: domainName,
      });
      setModalOpen(false);
      setDomainName('');
      await getOrganizationDomains(organizationId);
    } catch (err) {
      setModalError(
        err.response?.data?.msg || 'Could not add domain to organization!',
      );
    }
  };

  const deleteDomain = async (id) => {
    if (window.confirm('Are you sure to delete the domain?')) {
      setLoaded(true);
      try {
        const state = localStorage['appState'];
        const AppState = state ? JSON.parse(state) : null;
        const token = AppState?.user?.accessToken;
        const headers = { Authorization: `Bearer ${token}` };

        await axios.delete(
          `${API_BASE_URL}/admin/deleteOrganizationDomain/${id}`,
          { headers },
        );
        await getOrganizationDomains(organizationId);
      } catch (err) {
      } finally {
        setLoaded(false);
      }
    }
  };

  const closeDomainInfoModal = () => {
    setModalDomainInfoOpen(false);
    setDomainName('');
    setModalDomainInfo({});
  };

  const closeModal = () => {
    setModalOpen(false);
    setDomainName('');
    setModalError('');
  };

  const openAddDomainModal = () => {
    setModalOpen(true);
    setModalError('');
  };

  const handleName = (e) => setName(e.target.value);
  const handleGiftcards = (e) => setGiftcards(e.target.value);
  const handleDonations = (e) => setDonations(e.target.value);
  const cancelFrm = () => setRedirect(true);

  if (redirect) return <Redirect to='/admin/organizations' />;

  return (
    <div>
      <form onSubmit={updateOrganization}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color='primary'>
                <h4>Update Organization</h4>
              </CardHeader>
              <CardBody>
                {loaded && (
                  <div className='loaders'>
                    <img alt={'loader'} src={loader} />
                  </div>
                )}

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      type='text'
                      labelText='Name'
                      onChange={handleName}
                      value={name}
                      id='name'
                      name='name'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Tango giftcards uids'
                      id='giftcards'
                      name='giftcards'
                      onChange={handleGiftcards}
                      value={giftcards}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Tango donations uids'
                      id='donations'
                      name='donations'
                      onChange={handleDonations}
                      value={donations}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>

                {message && (
                  <GridContainer>
                    <GridItem xs={8} sm={8} md={6}>
                      <p style={{ color: 'red', padding: '10px 0' }}>
                        {message}
                      </p>
                    </GridItem>
                  </GridContainer>
                )}

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Button color='primary' type='submit'>
                      Update
                    </Button>
                    <Button
                      color='primary'
                      onClick={cancelFrm}
                      className='btn-cancel'
                      style={{ marginLeft: '5px' }}>
                      Cancel
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}></GridItem>
                </GridContainer>

                <GridContainer style={{ paddingTop: '30px' }}>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4>
                      <b>Domains</b>
                    </h4>
                    <br />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <Button
                      onClick={openAddDomainModal}
                      className='link'
                      color='primary'>
                      <span className='add-icon'>
                        <AddIcon />
                      </span>
                      Add Domain
                    </Button>
                  </GridItem>

                  <Table width='100%' id='template'>
                    <TableHead>
                      <TableRow>
                        <TableCell align='left'>Name</TableCell>
                        <TableCell align='left'>Status</TableCell>
                        <TableCell align='left'>Created by</TableCell>
                        <TableCell align='left'>Created at</TableCell>
                        <TableCell align='left'>Actions</TableCell>
                      </TableRow>
                    </TableHead>

                    {domains && domains.length > 0 ? (
                      <TableBody>
                        {(rowsPerPage > 0
                          ? domains.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage,
                            )
                          : domains
                        ).map((obj, index) => (
                          <TableRow className='txtalign' key={index}>
                            <TableCell width='20%'>
                              <b>{obj && obj.domain}</b>
                              <Link
                                to='#'
                                onClick={() => openDomainInfoModal(obj.domain)}>
                                <span className='info-icon'>
                                  <InfoIcon />
                                </span>
                              </Link>
                            </TableCell>
                            <TableCell width='20%'>
                              <b>{obj && obj.domain_status}</b>
                            </TableCell>
                            <TableCell width='20%'>
                              {obj && obj.created_by}
                            </TableCell>
                            <TableCell width='10%'>
                              {obj && obj.created_at}
                            </TableCell>
                            <TableCell width='10%'>
                              {obj && obj.id && (
                                <Link
                                  to='#'
                                  title='Delete Organization Domain'
                                  onClick={() => deleteDomain(obj.id)}>
                                  <span className='material-icons'>delete</span>
                                </Link>
                              )}{' '}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow className='txtalign'>
                          <TableCell colSpan={7} align='center'>
                            No Domains assigned!
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                    <TableFooter>
                      <TableRow align='center'>
                        <TablePagination
                          rowsPerPageOptions={[
                            5,
                            10,
                            25,
                            { label: 'All', value: -1 },
                          ]}
                          colSpan={7}
                          count={domains.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: { roboto: 'rows per page' },
                            native: true,
                          }}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </form>

      {/* Add domain modal */}
      <Modal open={modalOpen} onClose={closeModal}>
        <form onSubmit={addDomain}>
          <Box
            sx={modalStyle}
            className='makeStyles-card-96'
            noValidate
            autoComplete='off'>
            <CardHeader
              style={{ transform: 'translateY(-50%)' }}
              color='primary'>
              <h4>Add Organization Domain</h4>
            </CardHeader>
            <CardBody style={{ marginTop: '-20px' }}>
              {modalError && (
                <p
                  style={{
                    color: 'red',
                    padding: '0',
                    margin: 0,
                    fontSize: '15px',
                  }}>
                  {modalError}
                </p>
              )}
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='text'
                    labelText='Name'
                    onChange={(e) => setDomainName(e.target.value)}
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <main
                  style={{
                    padding: '20px',
                    gap: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}></main>
              </GridContainer>
              <div>
                <GridContainer>
                  <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    style={{ margin: '5px 20px' }}>
                    Add
                  </Button>
                  <Button
                    variant='contained'
                    color='info'
                    onClick={closeModal}
                    style={{ margin: '5px 20px' }}>
                    Close
                  </Button>
                </GridContainer>
              </div>
            </CardBody>
          </Box>
        </form>
      </Modal>

      {/* Domain info modal */}
      <Modal
        open={modalDomainInfoOpen}
        onClose={closeDomainInfoModal}
        loaded={!!modalDomainInfo?.id}>
        <Box
          sx={modalStyle}
          className='makeStyles-card-96'
          noValidate
          autoComplete='off'>
          <CardHeader style={{ transform: 'translateY(-50%)' }} color='primary'>
            <h4>Domain info</h4>
          </CardHeader>
          <CardBody style={{ marginTop: '-20px' }}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h4>
                  <b>Domain:</b> {modalDomainName}
                </h4>
                <br />
                <Divider />
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <b>Domain status: {modalDomainInfo?.state}</b>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <b>Created at:</b> {modalDomainInfo?.created_at}
                  </GridItem>
                </GridContainer>
                <br />
                <Divider />
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <p>
                      TXT records (known as SPF & DKIM) are required to send and
                      receive email with custom domain. Please add them to your
                      DNS records.
                    </p>
                  </GridItem>
                  {modalDomainInfo?.sending_dns_records &&
                    modalDomainInfo?.sending_dns_records.map(
                      (record, index) => (
                        <GridItem key={index} xs={12} sm={12} md={12}>
                          {record.value.includes('k=rsa') ? (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                padding: '20px',
                              }}>
                              <label style={labelStyle}>
                                <b>DKIM</b>
                              </label>
                              <label style={labelStyle}>
                                <b>Status:</b> {record.valid}
                              </label>
                              <label style={labelStyle}>
                                <b>Domain:</b> {record.name}
                              </label>
                              <TextField
                                variant='outlined'
                                multiline
                                inputProps={{
                                  style: {
                                    fontSize: '14px',
                                    lineHeight: '1.3',
                                    width: '100%',
                                  },
                                }}
                                minRows={3}
                                maxRows={6}
                                width='100%'
                                value={record.value ?? ''}
                                name='dkim'
                              />
                            </div>
                          ) : (
                            ''
                          )}
                          {record.value.includes('v=spf') ? (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                padding: '20px',
                              }}>
                              <label style={labelStyle}>
                                <b>SPF</b>
                              </label>
                              <label style={labelStyle}>
                                <b>Status:</b> {record.valid}
                              </label>
                              <label style={labelStyle}>
                                <b>Domain:</b> {record.name}
                              </label>
                              <TextField
                                variant='outlined'
                                multiline
                                inputProps={{
                                  style: {
                                    fontSize: '14px',
                                    lineHeight: '1.3',
                                    width: '100%',
                                  },
                                }}
                                minRows={3}
                                maxRows={6}
                                width='100%'
                                value={record.value ?? ''}
                                name='dkim'
                              />
                            </div>
                          ) : (
                            ''
                          )}
                          {record.record_type === 'CNAME' ? (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                padding: '20px',
                              }}>
                              <label style={labelStyle}>
                                <b>CNAME</b>
                              </label>
                              <label style={labelStyle}>
                                <b>Status:</b> {record.valid}
                              </label>
                              <label style={labelStyle}>
                                <b>Domain:</b> {record.name}
                              </label>
                              <TextField
                                variant='outlined'
                                multiline
                                inputProps={{
                                  style: {
                                    fontSize: '14px',
                                    lineHeight: '1.3',
                                    width: '100%',
                                  },
                                }}
                                minRows={3}
                                maxRows={6}
                                width='100%'
                                value={record.value ?? ''}
                                name='dkim'
                              />
                            </div>
                          ) : (
                            ''
                          )}
                        </GridItem>
                      ),
                    )}
                </GridContainer>
              </GridItem>
            </GridContainer>
            <div>
              <GridContainer>
                <Button
                  variant='contained'
                  color='info'
                  onClick={closeDomainInfoModal}
                  style={{ margin: '5px 20px' }}>
                  Close
                </Button>
              </GridContainer>
            </div>
          </CardBody>
        </Box>
      </Modal>
    </div>
  );
};

export default UpdateOrganization;
