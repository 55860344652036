import React from 'react';
import PaymentMethodSettings from './PaymentMethodSettings';
import { API_BASE_URL } from 'config';
import axios from 'axios';

export default function GlobalSettings() {
  const onSave = async (formValues) => {
    try {
      const response = await axios.put(
        API_BASE_URL + '/settings/global/update',
        formValues,
      );
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };

  const getGLobalPaymentSettings = async () => {
    const res = await axios.get(API_BASE_URL + '/settings/global');
    return res.data;
  };

  return (
    <div>
      <h3>Global Settings</h3>
      <PaymentMethodSettings
        onSave={onSave}
        fetchFormValues={getGLobalPaymentSettings}
      />
    </div>
  );
}
