import { useEffect, useState } from 'react';
import { EmailDomainsApi } from '../../clients';

const DEFAULT_EMAIL_ADDRESS_PREFIX = 'postmaster';

const useEmailDomains = () => {
  const [organizationDomains, setOrganizationDomains] = useState([]);
  const [facilityDomainId, setFacilityDomainId] = useState('0');
  const [facilityEmailAddressPrefix, setFacilityEmailAddressPrefix] = useState(
    '',
  );

  useEffect(() => {
    getAndSetFacilityDomain().catch(console.error);
    getAndSetOrganizationDomains().catch(console.error);
  }, []);

  const getAndSetFacilityDomain = async () => {
    const emailSender = await getEmailSender();
    if (emailSender.email_sender_local_part) {
      setFacilityEmailAddressPrefix(emailSender.email_sender_local_part);
    } else {
      setFacilityEmailAddressPrefix(DEFAULT_EMAIL_ADDRESS_PREFIX);
    }
    if (emailSender.domain_id) {
      setFacilityDomainId(emailSender.domain_id);
    }
  };

  const getAndSetOrganizationDomains = async () => {
    const domains = await EmailDomainsApi.getOrganizationDomains();
    setOrganizationDomains([
      ...domains.data.domains,
    ]);
  };

  const selectedFacilityDomain = organizationDomains.find(
    (domain) => domain.id === facilityDomainId,
  );

  const getEmailSender = async () => {
    const response = await EmailDomainsApi.getEmailSender();
    return response.data;
  };

  const save = async () => {
    await EmailDomainsApi.updateEmailSender({
      email_sender_local_part: facilityEmailAddressPrefix,
      domain_id: facilityDomainId,
    });
  };

  return {
    organizationDomains,
    facilityDomainId,
    facilityEmailAddressPrefix,
    setFacilityEmailAddressPrefix,
    selectedFacilityDomain,
    setFacilityDomainId,
    save,
  };
};

export default useEmailDomains;
