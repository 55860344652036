import React, { useState, useEffect } from 'react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import PaymentMethodRow from './PaymentMethodRow';

const defaultFormValues = {
  emailPaymentMethods: {
    isAchEnabled: false,
    isGiftCardsEnabled: false,
    isChecksEnabled: false,
    isDonationsEnabled: false,
  },
  letterPaymentMethods: {
    isAchEnabled: false,
    isChecksEnabled: false,
  },
  manualPaymentMethod: {
    isChecksEnabled: false, // Added new state for Facility Checks
  },
};

export default function PaymentMethodSettings({ onSave, fetchFormValues }) {
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [originalValues, setOriginalValues] = useState(defaultFormValues);

  const getPaymentSettings = async () => {
    try {
      const data = await fetchFormValues();

      setFormValues(data);
      setOriginalValues(data);
    } catch (error) {
      console.error('Error fetching form values:', error);
    }
  };

  useEffect(() => {
    // Fetch form values using the provided fetchFormValues function
    getPaymentSettings();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const [category, key] = name.split('.');
    setFormValues((prevValues) => ({
      ...prevValues,
      [category]: {
        ...prevValues[category],
        [key]: value,
      },
    }));
  };

  const handleSave = async () => {
    await onSave(formValues);
    getPaymentSettings();
  };

  const handleCancel = () => {
    setFormValues(originalValues);
  };

  const handleDivClick = (category, key) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [category]: {
        ...prevValues[category],
        [key]: !prevValues[category][key],
      },
    }));
  };

  return (
    <Card className='dashboardCard table_card_height'>
      <CardBody
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
        }}>
        <h3 className='subHeading'>Email Payment Methods</h3>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PaymentMethodRow
              label='ACH'
              name='emailPaymentMethods.isAchEnabled'
              checked={formValues.emailPaymentMethods.isAchEnabled}
              onChange={handleInputChange}
              onDivClick={() =>
                handleDivClick('emailPaymentMethods', 'isAchEnabled')
              }
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <PaymentMethodRow
              label='Gift Cards'
              name='emailPaymentMethods.isGiftCardsEnabled'
              checked={formValues.emailPaymentMethods.isGiftCardsEnabled}
              onChange={handleInputChange}
              onDivClick={() =>
                handleDivClick('emailPaymentMethods', 'isGiftCardsEnabled')
              }
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <PaymentMethodRow
              label='Checks'
              name='emailPaymentMethods.isChecksEnabled'
              checked={formValues.emailPaymentMethods.isChecksEnabled}
              onChange={handleInputChange}
              onDivClick={() =>
                handleDivClick('emailPaymentMethods', 'isChecksEnabled')
              }
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <PaymentMethodRow
              label='Donations'
              name='emailPaymentMethods.isDonationsEnabled'
              checked={formValues.emailPaymentMethods.isDonationsEnabled}
              onChange={handleInputChange}
              onDivClick={() =>
                handleDivClick('emailPaymentMethods', 'isDonationsEnabled')
              }
            />
          </GridItem>
        </GridContainer>
        <h3 className='subHeading'>Letter Payment Methods</h3>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PaymentMethodRow
              label='ACH'
              name='letterPaymentMethods.isAchEnabled'
              checked={formValues.letterPaymentMethods.isAchEnabled}
              onChange={handleInputChange}
              onDivClick={() =>
                handleDivClick('letterPaymentMethods', 'isAchEnabled')
              }
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <PaymentMethodRow
              label='Checks'
              name='letterPaymentMethods.isChecksEnabled'
              checked={formValues.letterPaymentMethods.isChecksEnabled}
              onChange={handleInputChange}
              onDivClick={() =>
                handleDivClick('letterPaymentMethods', 'isChecksEnabled')
              }
            />
          </GridItem>
        </GridContainer>
        <h3 className='subHeading'>Facility Payment Methods</h3>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PaymentMethodRow
              label='Checks'
              name='manualPaymentMethod.isChecksEnabled'
              checked={formValues.manualPaymentMethod.isChecksEnabled}
              onChange={handleInputChange}
              onDivClick={() =>
                handleDivClick('manualPaymentMethod', 'isChecksEnabled')
              }
            />
          </GridItem>
        </GridContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}>
          <Button
            style={{ marginTop: '10px', marginBottom: '-10px' }}
            onClick={handleCancel}
            color='danger'>
            Cancel
          </Button>
          <Button
            style={{ marginTop: '10px', marginBottom: '-10px' }}
            onClick={handleSave}
            color='primary'>
            Save
          </Button>
        </div>
      </CardBody>
    </Card>
  );
}
