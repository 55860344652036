import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PaymentMethodSettings from './PaymentMethodSettings';
import { API_BASE_URL } from 'config';
import axios from 'axios';

export default function FacilitySettings() {
  const { id } = useParams();
  const [facility, setFacility] = useState(null);

  const onSave = async (formValues) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/settings/facility/update/${id}`,
        formValues,
      );
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };

  const getFacilityPaymentSettings = async () => {
    const res = await axios.get(`${API_BASE_URL}/settings/facility/${id}`);
    return res.data;
  };

  useEffect(() => {
    const fetchFacility = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/facilities/${id}`);
        setFacility(response.data);
      } catch (error) {
        console.error('Error fetching facility:', error);
      }
    };

    fetchFacility();
  }, [id]);

  return (
    <div>
      <h3>Facility Settings ({facility ? facility.name : 'Loading...'})</h3>
      <PaymentMethodSettings
        onSave={onSave}
        fetchFormValues={getFacilityPaymentSettings}
      />
    </div>
  );
}
