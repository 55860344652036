import axios from 'axios';
import { Component } from 'react';
import { Link } from 'react-router-dom';

import { API_BASE_URL } from '../../config';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
// import Table from "components/Table/Table.js";
import AddIcon from '@material-ui/icons/Add';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import loader from '../../assets/img/loader.svg';
// components for table.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { TablePaginationActions } from './common';

export async function getOrganizations() {
  try {
    return await axios.get(`${API_BASE_URL}/getOrganizations`);
  } catch (err) {
    throw err;
  }
}

export default class Organizations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizations: [{}],
      isLoggedIn: false,
      user: '',
      errmsg: '',
      loaded: false,
      page: 0,
      rowsPerPage: 25,
    };

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleRowsPerPage = this.handleRowsPerPage.bind(this);
  }

  //   functions for table pagination
  handleChangePage(event, newPage) {
    this.setState({ page: newPage });
  }
  handleRowsPerPage(event) {
    let rows = parseInt(event.target.value);
    this.setState({ rowsPerPage: rows });
    this.setState({ page: 0 });
  }
  componentDidMount() {
    this.setState({ loaded: true });
    let state = localStorage['appState'];
    let AppState;

    if (state) {
      AppState = JSON.parse(state);
      this.setState({ isLoggedIn: AppState.isLoggedIn, user: AppState.user });
    }

    if (AppState?.user.role != 'admin') {
      window.location.href = '/client/error';
    }

    getOrganizations()
      .then((resp) => {
        if (resp?.data?.errmsg) {
          this.setState({
            errmsg: resp.data.errmsg,
            loaded: false,
          });
          return;
        }

        this.setState({
          organizations: resp?.data?.organizations,
          loaded: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color='primary'>
              <h4>Organizations</h4>
              {this.state.errmsg && (
                <p style={{ color: '#ff0000' }}>{this.state.errmsg}</p>
              )}
            </CardHeader>

            <CardBody>
              <div>
                {this.state.loaded && (
                  <div className='loaders'>
                    <img src={loader} />
                  </div>
                )}
                <div className='t-header'>
                  <div className='t-header-left'>
                    <Link to={`/admin/createOrganization`} className='link'>
                      <span className='add-icon'>
                        <AddIcon />
                      </span>
                      Create Organization
                    </Link>
                  </div>
                  <div className='t-header-right'></div>
                </div>

                <Table width='100%' id='template'>
                  <TableHead>
                    <TableRow>
                      <TableCell align='left'>Name</TableCell>
                      <TableCell align='left'>Created by</TableCell>
                      <TableCell align='left'>Created at</TableCell>
                      <TableCell align='left'>Custom email domain</TableCell>
                      <TableCell align='left'>Giftcard uids</TableCell>
                      <TableCell align='left'>Donation uids</TableCell>
                      <TableCell align='left'>Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  {this.state.organizations &&
                  this.state.organizations.length > 0 ? (
                    <TableBody>
                      {(this.state.rowsPerPage > 0
                        ? this.state.organizations.slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage,
                          )
                        : this.state.organizations
                      ).map((obj, index) => (
                        <TableRow className='txtalign' key={index}>
                          <TableCell width='20%'>{obj && obj.name}</TableCell>
                          <TableCell width='20%'>
                            {obj && obj.created_by}
                          </TableCell>
                          <TableCell width='10%'>
                            {obj && obj.created_at}
                          </TableCell>
                          <TableCell width='10%'>
                            {obj && obj.domains}
                          </TableCell>
                          <TableCell width='10%'>
                            {(obj && obj.tango_giftcards_uids?.length) || 0}
                          </TableCell>
                          <TableCell width='10%'>
                            {(obj && obj.tango_donations_uids?.length) || 0}
                          </TableCell>
                          <TableCell width='10%'>
                            {obj && obj.id && (
                              <Link
                                to={`/admin/updateOrganization/${obj.id}`}
                                title='Update Organization'>
                                <span className='material-icons'>edit</span>
                              </Link>
                            )}{' '}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell>No Organization Data Found!</TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  <TableFooter>
                    <TableRow align='center'>
                      <TablePagination
                        rowsPerPageOptions={[
                          5,
                          10,
                          25,
                          { label: 'All', value: -1 },
                        ]}
                        colSpan={7}
                        count={this.state.organizations.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        SelectProps={{
                          inputProps: { roboto: 'rows per page' },
                          native: true,
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
