import React, { useState } from 'react';
import { Modal, Box, Typography } from '@mui/material';
import { EditRounded } from '@material-ui/icons';
import Button from 'components/CustomButtons/Button';
import ManageFiles from './ManageFiles';
import { modalStyle } from 'views/Clients/Claim';
import { updateRefund } from './UpdateRefundModal';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import {
  REFUND_STATUSES,
  REFUND_SUB_STATUS,
} from './FacilityTransaction/FacilityTransaction';
import { REFUND_TYPES } from 'utility';
import ConfirmationModal from 'components/ConfirmationModal';
import { REFUND_METHOD } from 'utility';

export default function IssueCheckModal({
  currentCheckRefund,
  setCurrentCheckRefund,
  setCurrentInfo,
  setOriginalInfo,
  setModalErr,
  processPayment,
  role,
  setLoading,
}) {
  const [addFiles, setAddFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const getWarningMessages = (isForConfirm) => {
    const messages = [];
    if (
      currentCheckRefund.refund_sub_status ===
        REFUND_SUB_STATUS.PAYEE_EXCEPTION &&
      !addFiles.length
    ) {
      messages.push('Supporting documents are not attached to this refund.');
    }
    if (
      currentCheckRefund.refund_sub_status ===
        REFUND_SUB_STATUS.PAYEE_EXCEPTION &&
      !currentCheckRefund.memo
    ) {
      messages.push('Addenda information missing from memo field.');
    }

    if (
      currentCheckRefund.refund_sub_status ===
        REFUND_SUB_STATUS.ADDENDA_MISSING &&
      !currentCheckRefund.memo
    ) {
      messages.push('Addenda information missing from memo field.');
    }

    if (
      (currentCheckRefund.refund_sub_status ===
        REFUND_SUB_STATUS.PAYEE_EXCEPTION &&
        (!addFiles.length || !currentCheckRefund.memo)) ||
      !messages.length
    ) {
      messages.push(
        isForConfirm
          ? 'If you would still like to proceed to issue a check please press "Confirm".'
          : 'If you would still like to proceed to issue a check press the "Issue Check" button below to confirm',
      );
    }

    return (
      <div
        style={{
          color: 'red',
          padding: '0',
          margin: 0,
          fontSize: '15px',
          marginBottom: '20px',
        }}>
        <ul style={{ paddingLeft: '20px' }}>
          {messages.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </ul>
      </div>
    );
  };

  const onIssueCheck = async () => {
    try {
      setConfirmOpen(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setModalErr(err.response?.data?.message);
    }
  };

  const handleConfirm = async (isConfirmed) => {
    setConfirmOpen(false);
    if (!isConfirmed) return;

    try {
      setLoading(true);
      if (addFiles.length || deletedFiles.length) {
        await updateRefund({
          id: currentCheckRefund.id,
          deletedFiles,
          files: addFiles,
          role,
          disableMessage: true,
        });
      }
      await processPayment(currentCheckRefund.id);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setModalErr(err.response?.data?.message);
    }
  };

  const isCheckClaimable =
    currentCheckRefund.refund_status === REFUND_STATUSES.UNCLAIMED ||
    currentCheckRefund.refund_status === REFUND_STATUSES.ESCHEATED ||
    currentCheckRefund.refund_status === REFUND_STATUSES.EXCEPTION;

  return (
    <>
      <Modal
        open={!!currentCheckRefund}
        onClose={() => setCurrentCheckRefund(null)}>
        <Box
          sx={modalStyle}
          className='makeStyles-card-96'
          noValidate
          autoComplete='off'>
          <CardHeader style={{ transform: 'translateY(-50%)' }} color='primary'>
            <h4>
              {currentCheckRefund.refund_type === REFUND_TYPES.REGULAR
                ? 'Process Payment for Consumer Payment'
                : currentCheckRefund.refund_sub_status ===
                  REFUND_SUB_STATUS.PAYEE_EXCEPTION
                ? 'Payment For Payee Exception'
                : 'Process Payment For Non Payee Exception'}
            </h4>
          </CardHeader>
          <CardBody>
            {currentCheckRefund.refund_type === REFUND_TYPES.INSURANCE &&
              currentCheckRefund.refund_status === REFUND_STATUSES.EXCEPTION &&
              getWarningMessages(false)}
            {currentCheckRefund.refund_status === REFUND_STATUSES.REQUESTED &&
            currentCheckRefund.refund_method === REFUND_METHOD.CHECK
              ? 'Check Has Already Been Requested'
              : !isCheckClaimable
              ? 'Check Cannot Be Issued For This Refund'
              : null}

            {currentCheckRefund && (
              <div>
                <p style={{ marginBottom: '7px', fontWeight: 'bold' }}>
                  Address on file:{' '}
                  <a
                    onClick={() => {
                      setCurrentInfo(currentCheckRefund);
                      setOriginalInfo(currentCheckRefund);
                      setModalErr('');
                    }}>
                    <EditRounded
                      style={{ cursor: 'pointer' }}
                      fontSize='small'
                    />
                  </a>
                </p>
                <p style={{ marginBottom: '7px' }} className='page-desc'>
                  {currentCheckRefund.refund_type === 'insurance'
                    ? currentCheckRefund.business_payee_name
                    : currentCheckRefund.first_name +
                      ' ' +
                      currentCheckRefund.last_name}
                </p>
                <p style={{ marginBottom: '7px' }} className='page-desc'>
                  {currentCheckRefund.refund_to_address_line1},{' '}
                  {currentCheckRefund.refund_to_address_line2}
                </p>
                <p style={{ marginBottom: '7px' }} className='page-desc'>
                  {currentCheckRefund.refund_to_city},{' '}
                  {currentCheckRefund.refund_to_state},{' '}
                  {currentCheckRefund.refund_to_zip}{' '}
                </p>

                {currentCheckRefund.refund_type === 'insurance' ? (
                  <>
                    <p style={{ marginBottom: '7px', fontWeight: 'bold' }}>
                      Memo:
                    </p>
                    <p style={{ marginBottom: '7px' }} className='page-desc'>
                      {currentCheckRefund.memo || 'N/A'}
                    </p>
                  </>
                ) : (
                  ''
                )}
              </div>
            )}
            {currentCheckRefund.refund_type === 'insurance' && (
              <ManageFiles
                addFiles={addFiles}
                setAddFiles={setAddFiles}
                refund={currentCheckRefund}
                setDeletedFiles={setDeletedFiles}
                deletedFiles={deletedFiles}
                role={role}
              />
            )}
            {!!addFiles.length && (
              <p
                style={{
                  fontSize: '15px',
                  marginTop: '20px',
                  fontWeight: 'bold',
                }}>
                *By clicking "Issue check" you are issuing a check and attaching
                the above documents.
              </p>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                marginTop: '30px',
              }}>
              <Button
                onClick={onIssueCheck}
                type='button'
                variant='contained'
                disabled={!isCheckClaimable}
                color='rose'>
                Issue check
              </Button>
            </div>
          </CardBody>
        </Box>
      </Modal>

      <ConfirmationModal
        open={confirmOpen}
        onClose={handleConfirm}
        onConfirm={handleConfirm}>
        {getWarningMessages(true)}
      </ConfirmationModal>
    </>
  );
}
