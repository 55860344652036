import React from 'react';
import { Switch } from '@material-ui/core';

export default function PaymentMethodRow({
  label,
  name,
  checked,
  onChange,
  onDivClick,
}) {
  return (
    <span
      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      onClick={onDivClick}>
      <label style={{ flex: 1, cursor: 'pointer' }}>{label}</label>
      <Switch
        name={name}
        checked={checked}
        onChange={(value) =>
          onChange({
            target: {
              name: name,
              value: value.target.checked,
            },
          })
        }
      />
    </span>
  );
}
