/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { PostHogProvider } from 'posthog-js/react';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  Redirect,
  Route,
  Switch,
  BrowserRouter as Router,
  useHistory,
} from 'react-router-dom';
import Admin from 'layouts/Admin.js';
import Client from 'layouts/Client.js';
import Facility from 'layouts/Facility.js';
import RTL from 'layouts/RTL.js';
import User from 'layouts/User.js';

import axios from 'axios';
import NeedHelp from 'components/NeedHelp/NeedHelp.jsx';
import { POSTHOG_KEY } from 'config.js';
import { POSTHOG_HOST } from 'config.js';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Claim from 'views/Clients/Claim.js';
import './assets/css/material-dashboard-react.css?v=1.9.0';
import './assets/scss/global.scss';
import { createMuiTheme, MuiThemeProvider, Modal } from '@material-ui/core';
import moment from 'moment';
import { API_BASE_URL } from 'config';
import InactivityModal from 'components/InactivityModal/InactivityModal';
import { INACTIVITY_LIMIT } from 'config';
import { INACTIVITY_POPUP_DURATION } from 'config';

axios.defaults.withCredentials = true;

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#3AB795',
    },
    primary1Color: {
      main: '#3AB795',
    },
  },
});

export const isPathOpen = (path) => {
  return (
    path.includes('user/login') ||
    path.includes('user/facilitylogin') ||
    path.includes('user/activation')
  );
};

const App = () => {
  const history = useHistory();

  const handleResponseError = async (error) => {
    try {
      toast.error(error.response.data.message ?? error.response?.data.msg, {
        toastId: error.response.data.message ?? error.response?.data.msg,
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      if (isPathOpen(window.location.pathname)) {
        return Promise.reject(error);
      }
      const role = error.response.data.redirect;

      if (error.response?.status === 401) {
        history.push(
          `/user/${
            (role === 'admin' ? 'login' : 'facilitylogin') +
            `?redirect=${window.location.pathname}`
          }`,
        );
      }
    } catch (err) {
      console.error('Unexpected error in handleResponseError:', err);
    }
    return Promise.reject(error);
  };

  const ping = async () => {
    const appState = JSON.parse(localStorage.getItem('appState'));
    const role = appState?.user?.role;
    const currentPath = window.location.pathname;

    if (appState?.isLoggedIn && !isPathOpen(currentPath)) {
      const res = await axios.get(
        API_BASE_URL + '/ping' + (role === 'admin' ? '-admin' : ''),
      );
    }
  };

  useEffect(() => {
    const handleActivity = () => {
      const lastActivity = localStorage.getItem('lastActivity');
      const lastActivityInSecs = moment().diff(moment(lastActivity), 'seconds');

      if (lastActivityInSecs < INACTIVITY_LIMIT - INACTIVITY_POPUP_DURATION) {
        localStorage.setItem('lastActivity', new Date());
      }
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    const resInterceptor = axios.interceptors.response.use(function (response) {
      toast.success(response.data.message ?? response.data.msg, {
        position: 'bottom-right',
        autoClose: 5000,
      });
      return response;
    }, handleResponseError);

    const intervalId = setInterval(async () => {
      const lastActivity = localStorage.getItem('lastActivity');
      const lastActivityInSecs = moment().diff(moment(lastActivity), 'seconds');

      if (lastActivityInSecs <= 10) {
        ping();
      }
    }, 10000);

    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      axios.interceptors.response.eject(resInterceptor);
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <PostHogProvider
          apiKey={POSTHOG_KEY}
          options={{ api_host: POSTHOG_HOST }}></PostHogProvider>

        <ToastContainer
          position='bottom-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <InactivityModal onPing={ping} />

        <Switch>
          <Route path='/facility' component={Facility} />
          <Route path='/admin' component={Admin} />
          <Route path='/client' component={Client} />
          <Route path='/user' component={User} />
          <Route path='/rtl' component={RTL} />
          <Route path='/claim' component={Claim} />
          <Redirect from='/' to='/claim' />
        </Switch>
        <NeedHelp />
      </MuiThemeProvider>
    </>
  );
};

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root'),
);
