import { FormControl, MenuItem } from '@mui/material';
import InputLabel from '@material-ui/core/InputLabel';
import { Select } from '@material-ui/core';
import GridItem from '../../../../components/Grid/GridItem';
import CustomInput from '../../../../components/CustomInput/CustomInput';

const EditEmailSender = ({
  localPartDisabled,
  domainDisabled,
  organizationDomains,
  facilityDomainId,
  setEmailDomain,
  facilityEmailAddressPrefix,
  setFacilityEmailAddressPrefix,
}) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}>
      <GridItem xs={4} sm={4} md={4}>
        <CustomInput
          disabled={localPartDisabled}
          type='text'
          labelText='Email Local Part'
          onChange={(e) => setFacilityEmailAddressPrefix(e.target.value)}
          value={facilityEmailAddressPrefix}
          name='address_email_sender'
          formControlProps={{
            fullWidth: true,
          }}
        />
      </GridItem>
      <span
        style={{
          paddingTop: '36px',
        }}>
        @
      </span>
      <GridItem xs={4} sm={4} md={4}>
        <div
          style={{
            paddingTop: '18px',
          }}>
          <FormControl fullWidth>
            <InputLabel id='domain'>Email Domain</InputLabel>
            <Select
              disabled={domainDisabled}
              labelId='domain'
              id='domainId'
              value={facilityDomainId}
              label='Domain'
              onChange={setEmailDomain}>
              {organizationDomains &&
                organizationDomains.map((obj) => (
                  <MenuItem
                    value={obj.id && obj.id.toString()}
                    key={'domain_' + obj.id + obj.key}>
                    {obj.domain}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </GridItem>
    </div>
  );
};

export default EditEmailSender;
