import React, { useEffect, useState } from 'react';
import { Modal, Box } from '@mui/material';
import Button from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import { modalStyle } from 'views/Clients/Claim';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import { PictureAsPdf, Close } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { API_BASE_URL } from '../../config';
import ManageFiles from './ManageFiles';
import axios from 'axios';
import { REFUND_TYPES } from 'utility';
import { REFUND_STATUSES } from './FacilityTransaction';
import { TRANSACTION_STATUSES } from 'utility';

export const updateRefund = async ({
  oldInfo,
  newInfo,
  comment,
  resendCommunication,
  id,
  changed,
  deletedFiles,
  files,
  role,
  disableMessage,
}) => {
  const formData = new FormData();

  if (oldInfo) formData.append('oldInfo', JSON.stringify(oldInfo));
  if (newInfo) formData.append('newInfo', JSON.stringify(newInfo));
  formData.append('comment', comment ?? '');
  formData.append('resendCommunication', resendCommunication ?? false);
  formData.append('id', id);
  formData.append('changed', changed ?? false);
  formData.append('deletedFiles', JSON.stringify(deletedFiles ?? []));
  if (newInfo?.memo) formData.append('memo', newInfo?.memo);
  if (newInfo?.business_payee_name)
    formData.append('business_payee_name', newInfo.business_payee_name);

  files.forEach((file) => {
    if (file.local) formData.append('files', file.file);
  });

  return await axios.post(
    API_BASE_URL +
      `/update_refund_info${role === 'admin' ? '_admin' : ''}/` +
      id +
      `?disableMessage=${disableMessage ?? false}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export default function UpdateRefundModal({
  currentInfo,
  originalInfo,
  modalErr,
  setModalErr,
  onInputChange,
  setCurrentInfo,
  setOriginalInfo,
  role,
  getRefunds,
  payments,
}) {
  const [addFiles, setAddFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);

  const updateInfo = async (e, currentInfo, originalInfo) => {
    e.preventDefault();

    const newInfo = {
      first_name: currentInfo.first_name?.trim(),
      last_name: currentInfo.last_name?.trim(),
      email: currentInfo.email?.trim(),
      refund_to_address_line1: currentInfo.refund_to_address_line1?.trim(),
      refund_to_address_line2: currentInfo.refund_to_address_line2?.trim(),
      refund_to_city: currentInfo.refund_to_city?.trim(),
      refund_to_state: currentInfo.refund_to_state?.trim(),
      refund_to_zip: currentInfo.refund_to_zip?.trim(),
      memo: currentInfo.memo?.trim(),
      business_payee_name: currentInfo.business_payee_name?.trim(),
    };

    const oldInfo = originalInfo;

    //check if any info has changed
    let changed = false;
    for (let key in newInfo) {
      if (newInfo[key] != oldInfo[key]?.trim()) {
        changed = true;
        break;
      }
    }
    if (changed && !currentInfo.comment) {
      toast.error('Please enter a comment', { autoClose: 2000 });
      return;
    }

    try {
      await updateRefund({
        oldInfo,
        newInfo,
        comment: currentInfo.comment,
        resendCommunication: currentInfo.resendCommunication,
        id: currentInfo.id,
        changed,
        deletedFiles,
        files: addFiles,
        role,
      });
      setCurrentInfo(null);
      setOriginalInfo(null);

      getRefunds({ clear: true, limit: payments.length });
    } catch (err) {
      console.log(err);

      setModalErr(err.response?.data?.message);
    }
  };

  const handleResendCommunicationChange = (e) => {
    if (
      currentInfo?.refund_status === REFUND_STATUSES.UNCLAIMED ||
      currentInfo?.refund_status === REFUND_STATUSES.ESCHEATED ||
      currentInfo.transaction_status === TRANSACTION_STATUSES.ACH_RETURNED
    ) {
      onInputChange(e);
    } else {
      toast.error(
        'You can only Resend Communications if the refund is in Unclaimed, Escheated or ACH Returned Status',
        { autoClose: 2000 },
      );
    }
  };

  return (
    <Modal
      open={currentInfo ? true : false}
      onClose={() => {
        setCurrentInfo(null);
        setOriginalInfo(null);
      }}>
      <form onSubmit={(e) => updateInfo(e, currentInfo, originalInfo)}>
        <Box
          sx={modalStyle}
          className='makeStyles-card-96'
          noValidate
          autoComplete='off'>
          <CardHeader style={{ transform: 'translateY(-50%)' }} color='primary'>
            <h4>Update Refund Info </h4>
          </CardHeader>
          <CardBody style={{ marginTop: '-20px' }}>
            {modalErr && (
              <p
                style={{
                  color: 'red',
                  padding: '0',
                  margin: 0,
                  fontSize: '15px',
                }}>
                {modalErr}
              </p>
            )}
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  type='email'
                  name='email'
                  labelText='Email'
                  value={currentInfo ? currentInfo['email'] : ''}
                  autoComplete='off'
                  onChange={onInputChange}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  type='text'
                  name='first_name'
                  labelText='First Name'
                  onChange={onInputChange}
                  value={currentInfo ? currentInfo['first_name'] : ''}
                  autoComplete='off'
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  type='text'
                  name='last_name'
                  labelText='Last Name'
                  onChange={onInputChange}
                  value={currentInfo ? currentInfo['last_name'] : ''}
                  autoComplete='off'
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  type='text'
                  name='refund_to_address_line1'
                  labelText='Address Line 1'
                  onChange={onInputChange}
                  value={
                    currentInfo ? currentInfo['refund_to_address_line1'] : ''
                  }
                  autoComplete='off'
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  type='text'
                  name='refund_to_address_line2'
                  labelText='Address Line 2'
                  onChange={onInputChange}
                  value={
                    currentInfo ? currentInfo['refund_to_address_line2'] : ''
                  }
                  autoComplete='off'
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  type='text'
                  name='refund_to_city'
                  labelText='City'
                  onChange={onInputChange}
                  value={currentInfo ? currentInfo['refund_to_city'] : ''}
                  autoComplete='off'
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  type='text'
                  name='refund_to_state'
                  labelText='State'
                  onChange={onInputChange}
                  value={currentInfo ? currentInfo['refund_to_state'] : ''}
                  autoComplete='off'
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  type='text'
                  name='refund_to_zip'
                  labelText='Zip Code'
                  onChange={onInputChange}
                  value={currentInfo ? currentInfo['refund_to_zip'] : ''}
                  autoComplete='off'
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              {currentInfo.refund_type === 'insurance' && (
                <GridItem xs={12} sm={12} md={12}>
                  <ManageFiles
                    addFiles={addFiles}
                    setAddFiles={setAddFiles}
                    refund={currentInfo}
                    setDeletedFiles={setDeletedFiles}
                    deletedFiles={deletedFiles}
                    role={role}
                  />
                </GridItem>
              )}
              {currentInfo?.refund_type === 'insurance' && (
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    type='text'
                    name='business_payee_name'
                    labelText='Business Payee Name'
                    onChange={onInputChange}
                    value={
                      currentInfo ? currentInfo['business_payee_name'] : ''
                    }
                    autoComplete='off'
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
              )}
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  type='text'
                  name='memo'
                  labelText='Check Memo Field'
                  onChange={onInputChange}
                  value={currentInfo ? currentInfo['memo'] : ''}
                  autoComplete='off'
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  type='text'
                  name='comment'
                  labelText='Comment (Required for Personal Data Changes)'
                  onChange={onInputChange}
                  value={currentInfo ? currentInfo['comment'] : ''}
                  autoComplete='off'
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
            </GridContainer>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '5px',
                }}>
                {currentInfo.refund_type === REFUND_TYPES.REGULAR && (
                  <>
                    {' '}
                    <input
                      type='checkbox'
                      name='resendCommunication'
                      labelText=''
                      onChange={handleResendCommunicationChange}
                      checked={!!currentInfo?.resendCommunication}
                    />
                    <label>Resend Communication</label>
                  </>
                )}
              </div>
              <Button variant='contained' color='rose' type='submit'>
                Update
              </Button>
            </div>
          </CardBody>
        </Box>
      </form>
    </Modal>
  );
}
