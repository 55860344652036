import React from 'react';
import { Typography } from '@mui/material';

const RefundFileCountCell = ({ refund }) => {
  return refund.process_status !== 0 ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
      }}>
      {Object.keys(refund.refund_summary).map((key) => {
        return refund.refund_summary[key] ? (
          <Typography fontSize={'14px'} key={key}>
            {key}: {refund.refund_summary[key]}
          </Typography>
        ) : null;
      })}
      <Typography fontSize={'14px'}>Total: {refund.total_records}</Typography>
    </div>
  ) : (
    <Typography fontSize={'14px'}>
      {refund.total_records}
    </Typography>
  );
};

export default RefundFileCountCell;
