import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { API_BASE_URL } from '../../config';
// @material-ui/core components
import axios from 'axios';
// core components
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import loader from '../../assets/img/loader.svg';
import {
  Chip,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { Table } from '@mui/material';
import TemplateModal from 'components/TemplateModal/TemplateModal';
import { TablePaginationActions } from './common';
export default class CreateOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      loaded: false,
      name: '',
      giftcards: '',
      donations: '',
      domains: [],
      organization_id: '',
      organizations: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handleGiftCards = this.handleGiftCards.bind(this);
    this.handleDonations = this.handleDonations.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    this.clearForm();
  }
  handleName(event) {
    this.setState({ name: event.target.value });
  }
  handleGiftCards(event) {
    this.setState({ giftcards: event.target.value });
  }
  handleDonations(event) {
    this.setState({ donations: event.target.value });
  }
  handleState(event) {
    this.setState({ state: event.target.value });
  }
  cancelfrm = () => {
    this.setState({ redirect: true });
  };
  /*** function to integrate api to create facility*/
  componentDidMount() {
    axios.get(API_BASE_URL + '/getOrganizations').then((res) => {
      console.log('organization', res.data.organizations);
      this.setState({ organizations: res.data.organizations });
    });
    let state = localStorage['appState'];
    let AppState;
    if (state) {
      AppState = JSON.parse(state);
    }
    if (AppState?.user?.role != 'admin') {
      window.location.href = '/client/error';
    }
  }
  createOrganization = async (e) => {
    e.preventDefault();
    console.log(this.state);
    if (this.state.name == '') {
      alert('Please enter Organization name');
      return false;
    } else {
      this.setState({ loaded: true });
      const formData = new FormData();
      formData.append('name', this.state.name);
      formData.append('giftcards', this.state.giftcards);
      formData.append('donations', this.state.donations);
      let state = localStorage['appState'];
      let AppState;
      if (state) {
        AppState = JSON.parse(state);
      }
      let token = AppState?.user?.accessToken;
      const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      };
      axios
        .post(API_BASE_URL + '/admin/addOrganization', formData, {
          headers: headers,
        })
        .then((response) => {
          return response;
        })
        .then((res) => {
          console.log('resp', res.data);
          if (!res.data?.err) {
            this.setState({ redirect: true, loaded: false });
          } else {
            alert(res.data?.msg);
            this.setState({ loaded: false, message: res.data.msg });
          }
        })
        .catch((err) => {
          this.setState({ loaded: false, message: err.response?.data?.msg });
          console.log(err);
        });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to='/admin/organizations' />;
    }
    return (
      <div>
        <form onSubmit={this.createOrganization}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color='primary'>
                  <h4>Create Organization</h4>
                </CardHeader>
                <CardBody>
                  {this.state.loaded && (
                    <div className='loaders'>
                      <img src={loader} />
                    </div>
                  )}
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        type='text'
                        labelText='Name'
                        onChange={this.handleName}
                        value={this.state.name}
                        id='name'
                        name='name'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Tango giftcards uids'
                        id='giftcards'
                        name='giftcards'
                        onChange={this.handleGiftCards}
                        value={this.state.giftcards}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText='Tango donations uids'
                        id='donations'
                        name='donations'
                        onChange={this.handleDonations}
                        value={this.state.donations}
                        type='text'
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  {this.state.message && (
                    <GridContainer>
                      <GridItem xs={8} sm={8} md={6}>
                        <p style={{ color: 'red', padding: '10px 0' }}>
                          {this.state.message}
                        </p>
                      </GridItem>
                    </GridContainer>
                  )}
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <Button color='primary' type='submit'>
                        Create
                      </Button>
                      <Button
                        color='primary'
                        onClick={this.cancelfrm}
                        className='btn-cancel'
                        style={{ marginLeft: '5px' }}>
                        Cancel
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}></GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </form>
        <TemplateModal
          defaultValues={this.state.pdfTemplate}
          onPdfTemplateSave={this.onPdfTemplateSave}
          templateModal={this.state.templateModal}
          closeModal={this.closeModal}
        />
      </div>
    );
  }
}
