import axios from 'axios';
import { API_BASE_URL } from '../../../config';

export class EmailDomainsApi {
  static updateEmailSender(sender) {
    return axios.put(API_BASE_URL + '/email-sender', {
      email_sender_local_part: sender.email_sender_local_part,
      domain_id: sender.domain_id,
    });
  }

  static getEmailSender() {
    return axios.get(API_BASE_URL + '/email-sender');
  }

  static getOrganizationDomains() {
    return axios.get(API_BASE_URL + '/getDomains/');
  }
}
