import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Moment from 'react-moment';

import { API_BASE_URL } from '../../config';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import loader from '../../assets/img/loader.svg';
import uploadIcon from '../../assets/img/upload.svg';
import { utcToLocal } from 'utility';
import { useDataMappingsForUpload } from 'api/hooks';
import { REFUND_FILE_PROCESS_STATUSES } from '../Dashboard/UploadedFiles';
import useRefundFilesWithSummary from '../../utils/hooks/refund-files/useRefundFilesWithSummary';
import RefundFileCountCell from '../../utils/components/RefundFileCountCell';
import { Typography } from '@mui/material';

const Uploadfile = () => {
  const [loaded, setLoaded] = useState(false);
  const [facilityFolder, setFacilityFolder] = useState('');
  const [file, setFile] = useState(null);
  const [filesList, setFilesList] = useState([{}]);
  const [uploadFileText, setUploadFileText] = useState('Browse for file');
  const [uploadFileStatus, setUploadFileStatus] = useState(false);
  const [uploadSuccessMessage, setUploadSuccessMessage] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState(false);
  const [dataMappings, setDataMappings] = useState([]);
  const [selectedDataMapping, setSelectedDataMapping] = useState(null);
  const [user, setUser] = useState(null);
  const {
    dataMappingsForUpload,
    loading: dataMappingForUploadLoading,
  } = useDataMappingsForUpload();

  useEffect(() => {
    if (dataMappingsForUpload.length > 1) {
      setDataMappings(dataMappingsForUpload);
    }
    if (dataMappingsForUpload.length === 1) {
      setSelectedDataMapping(dataMappingsForUpload[0].id);
    }
  }, [dataMappingsForUpload, dataMappingForUploadLoading]);

  const uploadFile = async () => {
    try {
      if (file == null) {
        alert('Please upload file');
        setUploadFileText('Browse for file');
        setUploadFileStatus(false);
        return false;
      } else if (!selectedDataMapping || selectedDataMapping === 'null') {
        alert('Please select a data mapping');
      } else {
        setLoaded(true);
        const formData = new FormData();
        formData.append('file', file);
        formData.append(
          'folder',
          user && user.name.toLowerCase().replace(/ /g, ''),
        );
        formData.append('data_mapping_id', selectedDataMapping);
        formData.append('user_id', user && user.id);

        await axios.post(API_BASE_URL + '/upload_file', formData);

        setFile(null);
        setUploadFileStatus(false);
        setLoaded(false);

        const uploadedFiles = await axios.get(API_BASE_URL + '/getFilesList');

        setFilesList(uploadedFiles && uploadedFiles.data);
        setUploadSuccessMessage(true);
        setUploadFileText('Browse for file');
        setLoaded(false);
      }
    } catch (error) {
      setLoaded(false);
      console.log(error);
    }
  };

  const handleUploadFile = (event) => {
    if (event.target.files[0] !== undefined) {
      setFile(event.target.files[0]);
      setUploadFileText(event.target.files[0].name);
      setUploadFileStatus(true);
    } else {
      alert('please select a file');
    }
  };

  useEffect(() => {
    setLoaded(true);
    const state = localStorage['appState'];
    let AppState;
    if (state) {
      AppState = JSON.parse(state);
      setUser(AppState.user);
    }

    const userId = AppState && AppState.user && AppState.user.id;
    if (!userId) return (window.location.href = '/user/facilitylogin');
    if (AppState && AppState.user && AppState.user.role == 'admin') {
      window.location.href = '/client/error';
    }
    setFacilityFolder(
      state.user && state.user.name.toLowerCase().replace(/ /g, ''),
    );
    axios
      .get(API_BASE_URL + '/getFilesList')
      .then((response) => {
        return response;
      })
      .then((uploadedFiles) => {
        setFilesList(uploadedFiles && uploadedFiles.data);
        setLoaded(false);
      })
      .catch((err) => {
        setLoaded(false);
      });
  }, []);

  const refundFilesWithSummary = useRefundFilesWithSummary(filesList);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <h2 className='subHeading'>Upload Refund File</h2>
        <Card className='dashboardCard table_card_height'>
          <CardBody>
            <div>
              {loaded && (
                <div className='loaders'>
                  <img src={loader} />
                </div>
              )}
              {uploadSuccessMessage && (
                <div className='uploadTxt'>
                  <p>
                    File Uploaded SuccessFully.It can take up to 30mins to
                    process your file.
                  </p>{' '}
                </div>
              )}
              {uploadErrorMessage && (
                <div className='uploadTxt'>
                  <p style={{ color: 'red' }}>
                    You have already uploaded this file.
                  </p>{' '}
                </div>
              )}
              <div className='t-header uploadCard'>
                <div className='t-header-left'>
                  <input
                    type='file'
                    onChange={handleUploadFile}
                    value=''
                    id='logoimg'
                  />
                  <img src={uploadIcon} />
                  <p>{uploadFileText}</p>
                </div>
              </div>
              <div className='uploadTxt'>
                {uploadFileStatus && (
                  <button className='btn' onClick={uploadFile}>
                    Upload
                  </button>
                )}
                <p>
                  <a href={API_BASE_URL + '/getSampleRefundFile'}>
                    {' '}
                    Download Sample Template{' '}
                  </a>{' '}
                </p>
              </div>
            </div>
            {!!dataMappings.length && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <div className='custom-search-box'>
                  <div className='custom-select'>
                    <select
                      value={selectedDataMapping}
                      className='full-width-elem'
                      id='status'
                      onChange={(e) => setSelectedDataMapping(e.target.value)}>
                      <option style={{ fontWeight: 'bold' }} value='null'>
                        Data Mappings
                      </option>
                      {dataMappings.map((item) => {
                        return (
                          <option
                            value={item.id}
                            className='parent'
                            style={{ fontWeight: 'bold' }}
                            key={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <span className='search-icon'></span>
                </div>
              </div>
            )}
          </CardBody>
        </Card>

        <Card className='dashboardCard table_card_height'>
          <CardBody>
            <table width='100%' id='template' className='paymentTable'>
              <thead>
                <tr>
                  <th width='10%'>Uploaded Date</th>
                  <th width='25%'>File Name</th>
                  <th width='15%'>File Status</th>
                  <th width='15%'>Total Amount</th>
                  <th width='15%'>Record Count</th>
                  <th width='15%'>Data Mapping Name</th>
                  <th width='15%'>Processed Date</th>
                </tr>
              </thead>
              {refundFilesWithSummary && refundFilesWithSummary.length > 0 ? (
                <tbody>
                  {refundFilesWithSummary.map((obj, index) => (
                    <tr className='txtalign' key={index}>
                      <td width='10%'>
                        <Typography fontSize={'14px'}>
                          {utcToLocal(obj.created_at, 'MM/DD/YYYY')}
                        </Typography>
                      </td>
                      <td width='25%'>
                        <Typography fontSize={'14px'}>
                          {obj && obj.filename}
                        </Typography>
                      </td>
                      <td width='15%'>
                        <Typography fontSize={'14px'}>
                          {REFUND_FILE_PROCESS_STATUSES[obj.process_status]}
                        </Typography>
                      </td>
                      <td width='15%'>
                        <Typography fontSize={'14px'}>
                          {obj.total_amount === null ? 'N/A' : obj.total_amount}
                        </Typography>
                      </td>
                      <td width='15%'>
                        <RefundFileCountCell refund={obj} />
                      </td>
                      <td>
                        <Typography fontSize={'14px'}>
                          {obj.data_mapping_group_name
                            ? obj.data_mapping_group_name
                            : '-'}
                        </Typography>
                      </td>
                      <td width='15%'>
                        <Typography fontSize={'14px'}>
                          {obj && obj.process_status == 0
                            ? 'NA'
                            : obj && obj.upload_date}{' '}
                          {obj.processed_at ? (
                            <Moment format='MM-DD-YYYY HH:mm'>
                              {obj.processed_at}
                            </Moment>
                          ) : (
                            '-'
                          )}
                        </Typography>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td>No File Uploaded</td>
                  </tr>
                </tbody>
              )}
            </table>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Uploadfile;
