import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { API_BASE_URL } from '../../config';

import axios from 'axios';
import GridItem from 'components/Grid/GridsItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

import logoimg from 'assets/img/imgpsh_fullsize_anim_front.png';
import { toast } from 'react-toastify';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtp, setIsOtp] = useState(false);
  const [mfaSession, setMfaSession] = useState('');
  const [mfaChallenge, setMfaChallenge] = useState('');
  const history = useHistory();
  const location = useLocation();

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleOtp = (event) => {
    setOtp(event.target.value);
  };

  const logindashboard = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('email', email.trim());
    formData.append('password', password);
    formData.append('otp', otp);
    formData.append('mfaSession', mfaSession);
    formData.append('mfaChallenge', mfaChallenge);
    localStorage.removeItem('appState');
    axios
      .post(API_BASE_URL + '/users/login', formData)
      .then((response) => {
        return response;
      })
      .then((res) => {
        if (res.data?.mfa) {
          setIsOtp(true);
          setMfaSession(res.data.mfaSession);
          setMfaChallenge(res.data.mfaChallenge);
        }
        if (res?.data?.resetToken) {
          localStorage.setItem('resetToken', res?.data?.resetToken);
          window.location.href = '/user/reset';
          return;
        }
        if (res?.data?.data) {
          let appState = {
            isLoggedIn: true,
            user: res && res.data && res.data.data,
          };
          localStorage['appState'] = JSON.stringify(appState);
          localStorage.setItem('lastActivity', new Date());
          console.log(appState);
          const queryParams = new URLSearchParams(location.search);
          const redirectPath =
            queryParams.get('redirect') || '/admin/dashboard';
          history.push(redirectPath);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.errmsg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <form onSubmit={logindashboard}>
      <GridContainer>
        <GridItem xs={12} sm={10} md={6} id='10'>
          <Card>
            <CardHeader color='primary'>
              <GridContainer alignItems='center' justify='space-between'>
                <GridItem xs={8} sm={8} md={6} className='mx-auto'>
                  <h4>Admin Login</h4>
                </GridItem>
                <GridItem xs={8} sm={8} md={6}>
                  <img src={logoimg} width='130' />
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {!isOtp ? (
                <GridContainer>
                  <GridItem xs={8} sm={8} md={6}>
                    <CustomInput
                      labelText='Email'
                      id='heading'
                      name='heading'
                      onChange={handleEmail}
                      value={email}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      dataTestId='inputEmail'
                    />
                  </GridItem>
                  <GridItem xs={8} sm={8} md={6}>
                    <CustomInput
                      labelText='Password'
                      id='subheading'
                      type='password'
                      name='subheading'
                      onChange={handlePassword}
                      value={password}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      dataTestId='inputPassword'
                    />
                  </GridItem>
                </GridContainer>
              ) : (
                <GridContainer>
                  <GridItem xs={8} sm={8} md={6}>
                    <CustomInput
                      labelText='OTP'
                      onChange={handleOtp}
                      value={otp}
                      type='text'
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={8} sm={8} md={6}></GridItem>
                </GridContainer>
              )}
              <GridContainer alignItems='center'>
                <GridItem xs={8} sm={8} md={6}>
                  <Button
                    color='primary'
                    type='submit'
                    dataTestId='loginButton'>
                    Login
                  </Button>
                </GridItem>
                <GridItem xs={8} sm={8} md={6}>
                  {isOtp ? (
                    <Link color='primary' onClick={logindashboard}>
                      Resend OTP
                    </Link>
                  ) : (
                    <Link color='primary' to='/user/forgotpassword'>
                      Forgot Password?
                    </Link>
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default Login;
