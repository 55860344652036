import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import Button from 'components/CustomButtons/Button';
import { modalStyle } from 'views/Clients/Claim';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';

export default function ConfirmationModal({
  open,
  onClose,
  onConfirm,
  children,
}) {
  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <Box
        sx={modalStyle}
        style={{
          width: '500px',
        }}
        className='makeStyles-card-96'
        noValidate
        autoComplete='off'>
        <CardHeader style={{ transform: 'translateY(-50%)' }} color='primary'>
          <h6>Confirm Action</h6>
        </CardHeader>
        <CardBody>
          {children}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginTop: '30px',
            }}>
            <Button
              onClick={() => onClose(false)}
              type='button'
              variant='contained'
              color='default'>
              Cancel
            </Button>
            <Button
              onClick={() => onConfirm(true)}
              type='button'
              variant='contained'
              color='rose'
              style={{ marginLeft: '10px' }}>
              Confirm
            </Button>
          </div>
        </CardBody>
      </Box>
    </Modal>
  );
}
