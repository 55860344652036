import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import axios from 'axios';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import { API_BASE_URL } from 'config';
import React, { useEffect, useMemo, useState } from 'react';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import Moment from 'react-moment';
import { DeleteForeverRounded } from '@material-ui/icons';
import { utcToLocal } from 'utility';
import FileProcessingErrorModal from 'components/FileProcessingErrorModal.jsx/FileProcessingErrorModal';
import { Typography } from '@mui/material';
import { REFUND_STATUSES } from '../Clients/FacilityTransaction';
import ConfirmationModal from '../../components/ConfirmationModal';
import useRefundFilesWithSummary from '../../utils/hooks/refund-files/useRefundFilesWithSummary';

export const REFUND_FILE_PROCESS_STATUSES = {
  0: 'Unprocessed',
  1: 'Processed',
  2: 'Canceled',
};

const UploadedFiles = () => {
  const [files, setFiles] = useState([]);
  const [fileStatus, setFileStatus] = useState('');
  const [facilities, setFacilities] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [facility, setFacility] = useState('');
  const [fileProcessingErrors, setFileProcessingErrors] = useState(null);
  const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);
  const [fileMetadata, setFileMetadata] = useState(null);
  const filesWithRefundSummary = useRefundFilesWithSummary(files);

  const selectFacility = (event) => {
    setFacility(event.target.value);
  };

  const handleSelectFileStatus = (event) => {
    setFileStatus(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getFiles = async () => {
    const filesList = await axios.post(API_BASE_URL + '/adminGetFilesList', {
      facilityId: facility,
      fileStatus,
    });
    setFiles(filesList.data);
  };

  useEffect(() => {
    getFiles().catch();
  }, [facility, fileStatus]);

  useEffect(async () => {
    const response = await axios.get(API_BASE_URL + '/facilitiesPro');

    setFacilities(response.data);
  }, []);

  const processFile = async (fileId) => {
    try {
      const isConfirmed = confirm(
        'Are you sure you want to process this file?',
      );
      if (!isConfirmed) {
        return;
      }
      await axios.get(API_BASE_URL + '/processFileById/' + fileId);
      getFiles().catch();
    } catch (err) {
      console.log(err);
      if (err.response.status === 400 && err.response.data.processingErrors) {
        console.log(err.response.data.processingErrors);
        setFileProcessingErrors(err.response.data.processingErrors);
      }
    }
  };

  const validateFile = async (fileId) => {
    try {
      await axios.get(
        API_BASE_URL + '/processFileById/' + fileId + '?validate=true',
      );
    } catch (err) {
      if (err.response.status === 400 && err.response.data.processingErrors) {
        setFileProcessingErrors(err.response.data.processingErrors);
      }
    }
  };

  const deleteCsvFile = async (id) => {
    if (!window.confirm('Are you sure you want to delete this file?')) {
      return;
    }

    await axios.delete(API_BASE_URL + '/deleteCsvFile/' + id);
    getFiles().catch();
  };

  const clearErrors = () => {
    setFileProcessingErrors(null);
  };

  const handleConfirmCancel = (isConfirmed) => {
    setConfirmCancelOpen(false);
    if (!isConfirmed) {
      setFileMetadata(null);
      return;
    }
    setConfirmCancelOpen(false);
    return cancelFile(fileMetadata.fileId);
  };

  const handleCancelClick = (fileId, fileName) => {
    setFileMetadata({ fileId, fileName });
    setConfirmCancelOpen(true);
  };

  const cancelFile = async (fileId) => {
    try {
      await axios.post(API_BASE_URL + '/refund-files/' + fileId + '/cancel');
      getFiles().catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };

  const getRowStyles = (row) => {
    if (row.process_status === 2) {
      return {
        backgroundColor: '#FFFACD',
      };
    }
    return {};
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color='primary'>
            <h4>Files Uploaded</h4>
          </CardHeader>
          <CardBody>
            <div>
              <div className='t-header'>
                <div className='t-header-right'>
                  <div className='filter-container'>
                    <div className='custom-select'>
                      <select
                        className='full-width-elem'
                        id='File Status'
                        onChange={handleSelectFileStatus}>
                        <option value='' style={{ fontWeight: 'bold' }}>
                          File Status
                        </option>
                        <option value='0' style={{ fontWeight: 'bold' }}>
                          Unprocessed
                        </option>
                        <option value='1' style={{ fontWeight: 'bold' }}>
                          Processed
                        </option>
                        <option value='2' style={{ fontWeight: 'bold' }}>
                          Canceled
                        </option>
                      </select>
                    </div>
                    <div className='custom-select'>
                      <select
                        className='full-width-elem'
                        id='Facility'
                        onChange={selectFacility}>
                        <option value='' style={{ fontWeight: 'bold' }}>
                          Facilities
                        </option>
                        {facilities.map((item) => {
                          return (
                            <React.Fragment key={item.facilityID}>
                              <option
                                value={item.facilityID}
                                className='parent'
                                style={{ fontWeight: 'bold' }}
                                key={item.facilityID}>
                                {item.facilityName}
                              </option>
                              {item.subFacilityList.length > 0 &&
                                item.subFacilityList.map((itemInner) => {
                                  return (
                                    <option
                                      value={itemInner.id}
                                      className='child'
                                      style={{ textAlign: 'center' }}
                                      key={itemInner.id}>
                                      --{itemInner.name}
                                    </option>
                                  );
                                })}
                            </React.Fragment>
                          );
                        })}
                      </select>
                    </div>
                    <span className='search-icon'></span>
                  </div>
                </div>
              </div>
              <div style={{ overflowX: 'auto' }}>
                <Table id='template'>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>File Uploaded Date</TableCell>
                      <TableCell>Facility name</TableCell>
                      <TableCell>File status</TableCell>
                      <TableCell>Uploaded By</TableCell>
                      <TableCell>File Name</TableCell>
                      <TableCell>Record Count</TableCell>
                      <TableCell>Total Amount</TableCell>
                      <TableCell>Data Mapping Name</TableCell>
                      <TableCell>Processed date</TableCell>
                      <TableCell width='5%'></TableCell>
                    </TableRow>
                  </TableHead>
                  {filesWithRefundSummary && files.length > 0 ? (
                    <TableBody>
                      {(rowsPerPage > 0
                        ? filesWithRefundSummary.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage,
                          )
                        : filesWithRefundSummary
                      ).map((obj, index) => (
                        <TableRow
                          className='txtalign'
                          key={index}
                          style={getRowStyles(obj)}>
                          <TableCell width='15%'>
                            <div
                              style={{
                                display: 'flex',
                                gap: '10px',
                              }}>
                              <Button
                                onClick={() => validateFile(obj.id)}
                                color='primary'
                                disabled={
                                  obj.process_status === 1 ||
                                  obj.process_status === 2
                                }>
                                Validate
                              </Button>
                              <Button
                                onClick={processFile.bind(null, obj.id)}
                                color='primary'
                                disabled={
                                  obj.process_status === 1 ||
                                  obj.process_status === 2
                                }>
                                Process Files
                              </Button>
                              <Button
                                onClick={() =>
                                  handleCancelClick(obj.id, obj.filename)
                                }
                                color='danger'
                                disabled={
                                  obj.process_status === 0 ||
                                  obj.process_status === 2
                                }>
                                Cancel
                              </Button>
                            </div>
                          </TableCell>
                          <TableCell>
                            {obj.created_at
                              ? utcToLocal(obj.created_at, 'MM/DD/YYYY')
                              : '-'}
                          </TableCell>
                          <TableCell>{obj && obj.name}</TableCell>
                          <TableCell>
                            {REFUND_FILE_PROCESS_STATUSES[obj.process_status]}
                          </TableCell>
                          <TableCell>{obj.uploaded_by}</TableCell>

                          <TableCell>
                            <span title={obj.filename}>
                              <Typography fontSize={14}>
                                {obj.filename}
                              </Typography>
                            </span>
                          </TableCell>
                          <TableCell width={200}>
                            {obj.process_status !== 0 ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: '2px',
                                }}>
                                {Object.keys(obj.refund_summary).map((key) => {
                                  return obj.refund_summary[key] ? (
                                    <Typography fontSize={'14px'} key={key}>
                                      {key}: {obj.refund_summary[key]}
                                    </Typography>
                                  ) : null;
                                })}
                                <Typography fontSize={'14px'}>
                                  Total: {obj.total_records}
                                </Typography>
                              </div>
                            ) : (
                              obj.total_records
                            )}
                          </TableCell>
                          <TableCell>
                            {obj.total_amount === null
                              ? 'N/A'
                              : obj.total_amount}
                          </TableCell>
                          <TableCell>
                            {obj.data_mapping_group_name
                              ? obj.data_mapping_group_name
                              : '-'}
                          </TableCell>
                          <TableCell>
                            {obj.processed_at ? (
                              <Moment
                                style={{ textAlign: 'center', width: '100%' }}
                                format='MM-DD-YY'>
                                {obj.processed_at}
                              </Moment>
                            ) : (
                              '-'
                            )}
                          </TableCell>
                          <TableCell width='5%'>
                            <a
                              href={
                                API_BASE_URL +
                                '/getFile?filePath=' +
                                obj.file_location
                              }>
                              <div>
                                <CloudDownloadIcon />
                              </div>
                            </a>
                            {obj.process_status !== 1 && (
                              <a onClick={() => deleteCsvFile(obj.id)}>
                                <div>
                                  <DeleteForeverRounded />
                                </div>
                              </a>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell>No Transaction Data Found</TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[
                          5,
                          10,
                          25,
                          { label: 'All', value: -1 },
                        ]}
                        colSpan={3}
                        count={files.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </div>
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <ConfirmationModal
        open={confirmCancelOpen}
        onClose={handleConfirmCancel}
        onConfirm={handleConfirmCancel}>
        Are you sure you want to cancel file {fileMetadata?.fileName}?
      </ConfirmationModal>
      <FileProcessingErrorModal
        errors={fileProcessingErrors}
        close={clearErrors}
      />
    </GridContainer>
  );
};

export default UploadedFiles;
