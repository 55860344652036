import { useEffect, useState } from 'react';

const { Bar } = require('react-chartjs-2');

const BarChart = ({ showCount, data }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    regularAmount: [],
    insuranceAmount: [],
    regularCount: [],
    insuranceCount: [],
  });

  useEffect(() => {
    setChartData({
      labels: data.map((item) => item.method),
      regularAmount: data.map((item) => item.regular?.amount || 0),
      insuranceAmount: data.map((item) => item.insurance?.amount || 0),
      regularCount: data.map((item) => item.regular?.count || 0),
      insuranceCount: data.map((item) => item.insurance?.count || 0),
    });
  }, [data]);

  return (
    <Bar
      options={{
        plugins: {
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                const datasetLabel = tooltipItem.dataset.label || '';
                const value = tooltipItem.formattedValue ?? '0.00';
                return showCount
                  ? `${datasetLabel}: ${value}`
                  : `${datasetLabel}: $${value}`;
              },
            },
          },
          legend: {
            display: true,
            labels: {
              boxWidth: 5,
            },
            position: 'top',
            textDirection: 'rtl',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            stacked: true,
            ticks: {
              precision: 0,
              callback: function (value) {
                return showCount ? value : '$' + value;
              },
            },
            grid: {
              color: 'rgba(0, 0, 0, 0)',
            },
          },
          x: {
            stacked: true,
            grid: {
              color: 'rgba(0, 0, 0, 0)',
            },
          },
        },
      }}
      data={{
        labels: chartData.labels,
        datasets: [
          {
            label: 'Consumer Payments',
            data: showCount ? chartData.regularCount : chartData.regularAmount,
            backgroundColor: '#0766AD',
            borderWidth: 0,
            borderRadius: 5,
          },
          {
            label: 'Insurance Payments',
            data: showCount
              ? chartData.insuranceCount
              : chartData.insuranceAmount,
            backgroundColor: '#FF6C22',
            borderWidth: 0,
            borderRadius: 5,
          },
        ],
      }}
    />
  );
};

export default BarChart;
