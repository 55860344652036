import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';

import { API_BASE_URL, API_BASE_IMG_URL } from '../../config';
// @material-ui/core components
import { makeStyles, useTheme } from '@material-ui/core/styles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
// import Table from "components/Table/Table.js";
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import Icon from '@material-ui/core/Icon';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import loader from '../../assets/img/loader.svg';

// components for table.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0',
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF',
    },
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

//const useStyles = makeStyles(styles);
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'>
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'>
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'>
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'>
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default class Charities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [{}],
      charities: [{}],
      template_delete_count: [{}],
      search_keyword: '',
      isLoggedIn: false,
      user: '',
      loaded: false,
      page: 0,
      rowsPerPage: 25,
    };

    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleRowsPerPage = this.handleRowsPerPage.bind(this);
  }

  handleKeyUp(event) {
    this.setState({ search_keyword: event.target.value, loaded: true });
    const requestOptions = {
      body: JSON.stringify({ keyword: event.target.value }),
    };
    axios
      .post(API_BASE_URL + '/Facilitiesearch', requestOptions)
      .then((response) => {
        return response;
      })
      .then((facility) => {
        //console.log("facilities",facility && facility.data)
        this.setState({ facilities: facility && facility.data, loaded: false });
      })
      .catch((err) => {
        console.log(err);
      });
    console.log('search value', event.target.value);
  }

  changestatus(id) {
    this.setState({ loaded: true });
    let token = this.state.user && this.state.user.accessToken;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(API_BASE_URL + '/updatestatus/' + id, { headers: headers })
      .then((response) => {
        return response;
      })
      .then((facilitiestatus) => {
        this.setState({ loaded: false });
        if (
          facilitiestatus &&
          facilitiestatus.data &&
          facilitiestatus.data.success
        ) {
          axios
            .get(API_BASE_URL + '/charities', { headers: headers })
            .then((response) => {
              return response;
            })
            .then((charity) => {
              //console.log("facilities",facility && facility.data)
              this.setState({
                charities: charity && charity.data,
                loaded: false,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  deleteCharity(id) {
    if (window.confirm('Are you sure to delete the charity?')) {
      this.setState({ loaded: true });
      let token = this.state.user && this.state.user.accessToken;

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      axios
        .get(API_BASE_URL + '/delete_charity/' + id, { headers: headers })
        .then((response) => {
          return response;
        })
        .then((deletestatus) => {
          axios
            .get(API_BASE_URL + '/charities', { headers: headers })
            .then((response) => {
              return response;
            })
            .then((charity) => {
              //console.log("facilities",facility && facility.data)
              this.setState({
                charities: charity && charity.data,
                loaded: false,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  //   functions for table pagination

  handleChangePage(event, newPage) {
    this.setState({ page: newPage });
  }

  handleRowsPerPage(event) {
    let rows = parseInt(event.target.value);
    this.setState({ rowsPerPage: rows });
    this.setState({ page: 0 });
  }
  componentDidMount() {
    this.setState({ loaded: true });
    let state = localStorage['appState'];
    let AppState;
    if (state) {
      AppState = JSON.parse(state);
      this.setState({ isLoggedIn: AppState.isLoggedIn, user: AppState.user });
    }

    let token = AppState && AppState.user && AppState.user.accessToken;

    if (AppState && AppState.user && AppState.user.role != 'admin') {
      window.location.href = '/client/error';
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(API_BASE_URL + '/charities', { headers: headers })
      .then((response) => {
        return response;
      })
      .then((charity) => {
        //console.log("facilities",facility && facility.data)
        this.setState({ charities: charity && charity.data, loaded: false });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // const classes = useStyles();
  render() {
    const emptyRows =
      this.state.rowsPerPage -
      Math.min(
        this.state.rowsPerPage,
        this.state.charities.length - this.state.page * this.state.rowsPerPage,
      );
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color='primary'>
              <h4>Charities</h4>
            </CardHeader>

            <CardBody>
              <div>
                {this.state.loaded && (
                  <div className='loaders'>
                    <img src={loader} />
                  </div>
                )}
                <div className='t-header'>
                  <div className='t-header-left'>
                    <Link to={`/admin/createcharity`} className='link'>
                      <span className='add-icon'>
                        <AddIcon />
                      </span>
                      Create Charity
                    </Link>
                  </div>
                  <div className='t-header-right'>
                    {/*<!--<div className="custom-search-box">
              <input type="search" className="seacrh-field" value={this.state.search_keyword} onChange={this.handleKeyUp} placeholder="Search by name,email and phone"/>
              <span className="search-icon">
                <SearchIcon/>
              </span>
            </div>-->*/}
                  </div>
                </div>
                {/* <table width="100%" id="template">
		  <tr><td> <Link to={`/admin/createFacility`}><span className="material-icons">
add
</span>Create Facility</Link> </td></tr>
		  </table> */}
                <Table width='100%' id='template'>
                  <TableHead>
                    <TableRow>
                      <TableCell align='left'>Name</TableCell>
                      <TableCell align='left'>Email</TableCell>
                      <TableCell align='left'>Phone</TableCell>
                      <TableCell align='left'>Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  {this.state.charities && this.state.charities.length > 0 ? (
                    <TableBody>
                      {(this.state.rowsPerPage > 0
                        ? this.state.charities.slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                              this.state.rowsPerPage,
                          )
                        : this.state.charities
                      ).map((obj, index) => (
                        <TableRow className='txtalign' key={index}>
                          <TableCell width='20%'>{obj && obj.name}</TableCell>
                          <TableCell width='20%'>{obj && obj.email}</TableCell>
                          <TableCell width='20%'>{obj && obj.phone}</TableCell>

                          <TableCell width='20%'>
                            <Link
                              to={`/admin/updatecharity/${obj.id}`}
                              title='Update Charity'>
                              <span className='material-icons'>edit</span>
                            </Link>{' '}
                            <a
                              style={{ cursor: 'pointer' }}
                              onClick={() => this.deleteCharity(obj.id)}
                              title='Delete Charity'>
                              {' '}
                              <span className='material-icons'>delete</span>
                            </a>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell>No Charity Data Found!</TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  <TableFooter>
                    <TableRow align='center'>
                      <TablePagination
                        rowsPerPageOptions={[
                          5,
                          10,
                          25,
                          { label: 'All', value: -1 },
                        ]}
                        colSpan={3}
                        count={this.state.charities.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        SelectProps={{
                          inputProps: { roboto: 'rows per page' },
                          native: true,
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
