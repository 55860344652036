import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Link, Redirect } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Hidden from '@material-ui/core/Hidden';
import Poppers from '@material-ui/core/Popper';
import Divider from '@material-ui/core/Divider';
import {
  API_BASE_URL,
  API_BASE_IMG_URL,
  PUBLIC_ASSETS_BASE_URL,
} from '../../config';
import axios from 'axios';

import Button from 'components/CustomButtons/Button.js';

import styles from 'assets/jss/material-dashboard-react/components/headerLinksStyle.js';
//import userProfile from "../../assets/img/profile.svg";
import userProfile from '../../assets/img/profile.png';
import arrowDown from '../../assets/img/arrow-down-sign-to-navigate.svg';
import { ExitToAppRounded, LocalDiningOutlined } from '@material-ui/icons';
import { usePostHog } from 'posthog-js/react';

const useStyles = makeStyles(styles);

export const handleLogout = async () => {
  console.log('handleLogout');

  let AppState = localStorage.getItem('appState');
  AppState = JSON.parse(AppState);
  localStorage.clear();
  try {
    const response = await axios.post(
      `${API_BASE_URL}/${
        AppState?.user?.role === 'admin'
          ? 'users/adminsignout'
          : 'users/signout'
      }`,
    );

    if (response?.data?.redirectTo) {
      window.location.href = `/user/${
        response.data.redirectTo === 'admin'
          ? 'login'
          : 'facilitylogin' + '?redirect=' + window.location.pathname
      }`;
    } else {
      window.location.href = `/user/facilitylogin`;
    }
  } catch (error) {
    console.error('Logout failed:', error);
  }
};

export default function AdminNavbarLinks() {
  const classes = useStyles();
  const [openProfile, setOpenProfile] = React.useState(null);

  const [isFacilitiesNavActive, setIsFacilitiesNavActive] = React.useState(
    false,
  );

  const [facilities, setFacilities] = React.useState([]);
  const [user, setUser] = React.useState({});

  const posthog = usePostHog();

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    // setOpenProfile(null);
  };

  const changeActiveFacility = async (facility) => {
    try {
      localStorage.setItem(
        'appState',
        JSON.stringify({
          isLoggedIn: true,
          name: facility.name,
          user: { ...facility, role: 'facility' },
        }),
      );
      const data = (
        await axios.post(API_BASE_URL + '/set-active-facility', {
          facility_id: facility.id,
        })
      ).data;

      posthog.group('organization', data.organization.id, {
        name: data.organization.name,
      });

      posthog.identify(localStorage.getItem('userId'), {
        name: data.email,
        email: data.email,
        organization: data.organization.name,
      });

      posthog.capture('select facility');

      localStorage.setItem('currentFacilityId', facility.id);

      //reload the page
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    let state = localStorage['appState'];
    let AppState;
    if (state) {
      AppState = JSON.parse(state);
    }
    setUser(AppState && AppState.user && AppState.user);
    const role = AppState?.user?.role;
    if (role === 'facility' || role === 'facility-user') {
      axios.get(API_BASE_URL + '/available-facilities').then((response) => {
        setFacilities(response.data.facilities);
      });
    }
  }, []);
  return (
    <div>
      <div
        className={isFacilitiesNavActive ? 'overlay' : ''}
        onClick={() => setIsFacilitiesNavActive(false)}></div>
      <Button
        color={window.innerWidth > 959 ? 'transparent' : 'white'}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-label='Dashboard'
        className={classes.buttonLink}>
        <Hidden mdUp implementation='css'>
          <p className={classes.linkText}>Dashboard</p>
        </Hidden>
      </Button>

      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? 'transparent' : 'white'}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? 'profile-menu-list-grow' : null}
          aria-haspopup='true'
          onClick={handleClickProfile}
          className={classes.buttonLink}>
          {/* <Person className={classes.icons} /> */}
          <div
            className='user_prfile'
            onClick={() => setIsFacilitiesNavActive(!isFacilitiesNavActive)}>
            <img
              style={{
                width: '40px',
                height: '40px',
                objectFit: 'cover',
                borderRadius: '50%',
              }}
              src={
                user?.role === 'facility'
                  ? PUBLIC_ASSETS_BASE_URL + user.image_upload
                  : userProfile
              }
            />
            <p>{user && user.name}</p>
            <img src={arrowDown} className='arrow' />
          </div>
          <Hidden mdUp implementation='css'>
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>

        {/* <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            ' ' +
            classes.popperNav
          }>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id='profile-menu-list-grow'
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}>
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role='menu'>
                    <MenuItem
                      onClick={handleLogout}
                      className={classes.dropdownItem}>
                      {console.log(Boolean(openProfile), openProfile)}
                      Logout ajsdh
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers> */}
      </div>
      <div
        className={
          'wrapper ' +
          (isFacilitiesNavActive === true
            ? 'animate-in'
            : isFacilitiesNavActive === false
            ? 'animate-out'
            : '')
        }>
        <div className='available-facilities'>
          {facilities.map((facility, index) => {
            return (
              <div
                key={facility.id}
                className='container'
                onClick={() => changeActiveFacility(facility)}>
                <img
                  className='logo'
                  src={PUBLIC_ASSETS_BASE_URL + facility.image_upload}
                  alt='Logo'
                />
                <div className='name'> {facility.name}</div>
              </div>
            );
          })}
        </div>
        <div className='logout'>
          <div className='icon' onClick={handleLogout}>
            <ExitToAppRounded />
          </div>
        </div>
      </div>
    </div>
  );
}
