import { useMemo } from 'react';
import { REFUND_STATUSES } from '../../../views/Clients/FacilityTransaction';

const useRefundFilesWithSummary = (files) => {
  return useMemo(() => {
    return files.map((file) => {
      const refund_summary = {
        [REFUND_STATUSES.UNCLAIMED]: 0,
        [REFUND_STATUSES.REQUESTED]: 0,
        [REFUND_STATUSES.COMPLETED]: 0,
        [REFUND_STATUSES.CANCELED]: 0,
        [REFUND_STATUSES.ESCHEATED]: 0,
        [REFUND_STATUSES.EXCEPTION]: 0,
      };

      if (file.refunds?.length) {
        file.refunds.forEach((refund) => {
          refund_summary[refund.refund_status] += 1;
        });
      }

      return {
        ...file,
        refund_summary,
      };
    });
  }, [files]);
};

export default useRefundFilesWithSummary;
