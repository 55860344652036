import { useState } from 'react';
import GridItem from '../../../../components/Grid/GridItem';
import EditEmailSender from './EditEmailSender';
import useEmailDomains from '../../../../api/hooks/email-domains/useEmailDomains';
import Button from '../../../../components/CustomButtons/Button';

const EmailSender = () => {
  const [edit, setEdit] = useState(false);
  const {
    facilityEmailAddressPrefix,
    facilityDomainId,
    organizationDomains,
    setFacilityDomainId,
    setFacilityEmailAddressPrefix,
    save,
  } = useEmailDomains();

  const handleEditClick = () => {
    setEdit(true);
  };

  const handleCancelClick = () => {
    setEdit(false);
  };

  const handleSaveClick = async () => {
    await save();
    setEdit(false);
  };

  const handleSetFacilityDomain = (e) => {
    if (e.target.value == '0') {
      setFacilityEmailAddressPrefix('postmaster');
    }
    setFacilityDomainId(e.target.value);
  };

  const showEditButton = organizationDomains.length > 1;

  return (
    <div
      style={{
        width: '50%',
        marginBottom: '88px',
      }}>
      <GridItem xs={12} xl={12} xm={12}>
        <h2
          style={{
            fontSize: '1.2rem',
            paddingBottom: '20px',
            paddingTop: '6px',
            fontWeight: 400,
          }}>
          Email Sender
        </h2>
      </GridItem>
      <GridItem xs={12} xl={12} xm={12}>
        <p>
          The following email will be used when sending out communications to
          customers. If the provided domain is successfully verified, you will
          have the option to use that domain.
        </p>
      </GridItem>
      <EditEmailSender
        localPartDisabled={!edit || facilityDomainId == '0'}
        domainDisabled={!edit}
        organizationDomains={organizationDomains}
        facilityDomainId={facilityDomainId}
        setEmailDomain={handleSetFacilityDomain}
        facilityEmailAddressPrefix={facilityEmailAddressPrefix}
        setFacilityEmailAddressPrefix={setFacilityEmailAddressPrefix}
      />
      <GridItem xs={12} xl={12} xm={12}>
        {edit ? (
          <>
            <Button
              variant='contained'
              color='primary'
              onClick={handleSaveClick}>
              Save
            </Button>
            <Button
              variant='contained'
              color='danger'
              onClick={handleCancelClick}>
              Cancel
            </Button>
          </>
        ) : (
          showEditButton && (
            <>
              <Button
                variant='contained'
                color='primary'
                onClick={handleEditClick}>
                Edit
              </Button>
            </>
          )
        )}
      </GridItem>
    </div>
  );
};

export default EmailSender;
