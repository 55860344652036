import React, { useEffect, useState } from 'react';
import { Modal, Box } from '@material-ui/core';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button';
import { modalStyle } from 'views/Clients/Claim';
import { handleLogout } from 'components/Navbars/AdminNavbarLinks';
import { INACTIVITY_LIMIT } from 'config';
import moment from 'moment';
import { isPathOpen } from 'index';
import { toast } from 'react-toastify';
import { INACTIVITY_POPUP_DURATION } from 'config';

const InactivityModal = ({ onPing }) => {
  const [timer, setTimer] = useState(0);
  const [showLogoutWarning, setShowLogoutWarning] = useState(false);

  useEffect(() => {
    const checkInactivityInterval = setInterval(() => {
      const lastActivity = localStorage.getItem('lastActivity');
      const lastActivityInSecs = moment().diff(moment(lastActivity), 'seconds');
      const appState = JSON.parse(localStorage.getItem('appState'));
      const isLoggedIn = appState?.isLoggedIn;

      if (
        lastActivityInSecs > INACTIVITY_LIMIT - INACTIVITY_POPUP_DURATION &&
        !isPathOpen(window.location.pathname) &&
        isLoggedIn
      ) {
        setShowLogoutWarning((data) => {
          if (!data) {
            return true;
          }
          return data;
        });
        const timeLeft = INACTIVITY_LIMIT - lastActivityInSecs;
        setTimer(timeLeft > 0 ? timeLeft : 0);
      } else {
        setShowLogoutWarning(false);
      }

      if (lastActivityInSecs >= INACTIVITY_LIMIT) {
        handleLogout();
        toast.error('You have been logged out due to inactivity.', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }, 1000);

    return () => clearInterval(checkInactivityInterval);
  }, []);

  return (
    <Modal
      open={showLogoutWarning}
      onClose={async () => {
        await handleLogout();
        setShowLogoutWarning(false);
      }}>
      <Box
        sx={modalStyle}
        className='makeStyles-card-96'
        noValidate
        autoComplete='off'>
        <CardHeader style={{ transform: 'translateY(-50%)' }} color='primary'>
          <h4>Inactivity Warning</h4>
        </CardHeader>
        <CardBody>
          <p>
            You will be logged out soon due to inactivity in {timer} seconds.
          </p>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginTop: '30px',
            }}>
            <Button
              color='rose'
              onClick={async () => {
                await handleLogout();
                setShowLogoutWarning(false);
              }}>
              Logout
            </Button>
            <Button
              color='primary'
              onClick={async () => {
                await onPing();
                localStorage.setItem('lastActivity', new Date());
                setShowLogoutWarning(false);
              }}>
              Stay
            </Button>
          </div>
        </CardBody>
      </Box>
    </Modal>
  );
};

export default InactivityModal;
