import React from 'react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  Switch,
} from '@material-ui/core';
import { usePostHog } from 'posthog-js/react';

const labelStyle = {
  fontSize: '12px',
  color: 'grey',
  cursor: 'pointer',
};

const dateFormats = [
  'MM/DD/YYYY',
  'YYYY/MM/DD',
  'DD/MM/YYYY',
  'YYYY/DD/MM',
  'MMDDYYYY',
  'YYYYMMDD',
  'DDMMYYYY',
  'YYYYDDMM',
];

export default function OutboundReporting({
  formValues,
  setFormValues,
  originalFormValues,
  setUpdatingBlocks,
  updatingBlocks,
  editId,
  isUpdating,
  onOutboundReportingCancel,
  updateOutboundReporting,
  availableColumns,
  addReportingColumn,
  updateReportingColumn,
  removeReportingColumn,
  handleInputChange,
}) {
  const posthog = usePostHog();

  const isNewRefundReportingEnabled = posthog.isFeatureEnabled(
    'new-refund-reporting',
  );

  return (
    isNewRefundReportingEnabled && (
      <Card className='dashboardCard table_card_height'>
        <CardBody
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
          }}>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <h3 className='subHeading'>Outbound Reporting</h3>
            <Switch
              name='outbound_reporting'
              checked={formValues.outbound_reporting}
              disabled={!isUpdating('outbound-reporting')}
              onChange={(value) =>
                handleInputChange({
                  target: {
                    name: 'outbound_reporting',
                    value: value.target.checked,
                  },
                })
              }
            />
          </span>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                type='text'
                labelText='File location for outbound reporting'
                disabled={!isUpdating('outbound-reporting')}
                onChange={handleInputChange}
                value={formValues.outbound_file_location}
                name='outbound_file_location'
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <div className='custom-select'>
                <FormControl>
                  <InputLabel id='outbound-file-delimiter-label'>
                    Outbound File Delimiter
                  </InputLabel>
                  <Select
                    disabled={!isUpdating('outbound-reporting')}
                    labelId='outbound-file-delimiter-label'
                    id='outbound-file-delimiter'
                    name='outbound_file_delimiter'
                    value={formValues.outbound_file_delimiter}
                    onChange={handleInputChange}
                    className='full-width-elem'>
                    <MenuItem value={'comma'}>Comma</MenuItem>
                    <MenuItem value={'pipe'}>Pipe</MenuItem>
                    <MenuItem value={'tab'}>Tab</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                type='text'
                labelText='Outbound File Extension'
                disabled={!isUpdating('outbound-reporting')}
                onChange={handleInputChange}
                value={formValues.outbound_file_extension}
                name='outbound_file_extension'
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                type='text'
                labelText='Outbound File Name'
                disabled={!isUpdating('outbound-reporting')}
                onChange={handleInputChange}
                value={formValues.outbound_file_name}
                name='outbound_file_name'
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <div className='custom-select'>
                <FormControl>
                  <InputLabel id='outbound-file-date-format-label'>
                    Date Format that will be added to the end of the file name
                  </InputLabel>
                  <Select
                    disabled={!isUpdating('outbound-reporting')}
                    labelId='outbound-file-date-format-label'
                    id='outbound-file-date-format'
                    name='outbound_file_date_format'
                    value={formValues.outbound_file_date_format}
                    onChange={handleInputChange}
                    className='full-width-elem'>
                    {dateFormats.map((format) => (
                      <MenuItem key={format} value={format}>
                        {format}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: '90px',
                }}>
                <FormControl>
                  <FormLabel style={labelStyle} id='outbound-encryption-label'>
                    Is the file encrypted??
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='outbound-encryption-label'
                    name='encrypt_outbound'
                    value={formValues.encrypt_outbound}
                    onChange={handleInputChange}>
                    <FormControlLabel
                      value='true'
                      disabled={!isUpdating('outbound-reporting')}
                      control={<Radio />}
                      label='Yes'
                    />
                    <FormControlLabel
                      value='false'
                      disabled={!isUpdating('outbound-reporting')}
                      control={<Radio />}
                      label='No'
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  minHeight: '90px',
                }}>
                <FormControl>
                  <FormLabel style={labelStyle} id='outbound-headers-label'>
                    Include headers in the file?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='outbound-headers-label'
                    name='include_headers_outbound'
                    value={formValues.include_headers_outbound}
                    onChange={handleInputChange}>
                    <FormControlLabel
                      value='true'
                      disabled={!isUpdating('outbound-reporting')}
                      control={<Radio />}
                      label='Yes'
                    />
                    <FormControlLabel
                      value='false'
                      disabled={!isUpdating('outbound-reporting')}
                      control={<Radio />}
                      label='No'
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </GridItem>
          </GridContainer>

          {formValues?.reporting_columns?.map((column, index) => (
            <GridContainer key={index}>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  type='text'
                  labelText='Column Name'
                  onChange={(e) =>
                    updateReportingColumn(index, 'column_name', e.target.value)
                  }
                  value={column.column_name}
                  name='column_name'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  disabled={!isUpdating('outbound-reporting')}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <div className='custom-select'>
                  <FormControl fullWidth>
                    <InputLabel id={`available-columns-label-${index}`}>
                      Available Columns
                    </InputLabel>
                    <Select
                      labelId={`available-columns-label-${index}`}
                      id={`available-columns-${index}`}
                      name='variable_name'
                      value={column.variable_name}
                      onChange={(e) =>
                        updateReportingColumn(
                          index,
                          'variable_name',
                          e.target.value,
                        )
                      }
                      disabled={!isUpdating('outbound-reporting')}>
                      {availableColumns?.map((col) => (
                        <MenuItem key={col.value} value={col.value}>
                          {col.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </GridItem>
              {column.variable_name === 'custom' && (
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    type='text'
                    labelText='Custom Hardcoded Value'
                    onChange={(e) =>
                      updateReportingColumn(
                        index,
                        'column_custom_value',
                        e.target.value,
                      )
                    }
                    value={column.column_custom_value}
                    name='column_custom_value'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    disabled={!isUpdating('outbound-reporting')}
                  />
                </GridItem>
              )}
              {column.variable_name !== 'custom' && (
                <GridItem xs={12} sm={12} md={4}>
                  <div className='custom-select'>
                    {column.variable_name &&
                      availableColumns.find(
                        (col) => col.value === column.variable_name,
                      )?.formats && (
                        <FormControl fullWidth>
                          <InputLabel id={`format-label-${index}`}>
                            Format
                          </InputLabel>
                          <Select
                            labelId={`format-label-${index}`}
                            id={`format-${index}`}
                            name='format'
                            value={column.format}
                            onChange={(e) =>
                              updateReportingColumn(
                                index,
                                'format',
                                e.target.value,
                              )
                            }
                            disabled={!isUpdating('outbound-reporting')}>
                            {availableColumns
                              .find((col) => col.value === column.variable_name)
                              ?.formats?.map((format) => (
                                <MenuItem
                                  key={format.value}
                                  value={format.value}>
                                  {format.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      )}
                  </div>
                </GridItem>
              )}
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  style={{ marginTop: '10px' }}
                  onClick={() => removeReportingColumn(index)}
                  color='danger'
                  disabled={!isUpdating('outbound-reporting')}>
                  Remove
                </Button>
              </GridItem>
            </GridContainer>
          ))}

          <div>
            <Button
              style={{ marginTop: '10px' }}
              onClick={addReportingColumn}
              color='primary'
              disabled={!isUpdating('outbound-reporting')}>
              Add Reporting Column
            </Button>
          </div>

          {editId && !updatingBlocks.includes('outbound-reporting') && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
              }}>
              <Button
                style={{ marginTop: '10px', marginBottom: '-10px' }}
                onClick={() =>
                  setUpdatingBlocks([...updatingBlocks, 'outbound-reporting'])
                }
                color='primary'>
                Edit
              </Button>
            </div>
          )}

          {updatingBlocks.includes('outbound-reporting') && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '20px',
              }}>
              <Button
                style={{ marginTop: '10px', marginBottom: '-10px' }}
                onClick={onOutboundReportingCancel}
                color='danger'>
                Cancel
              </Button>
              <Button
                style={{ marginTop: '10px', marginBottom: '-10px' }}
                onClick={updateOutboundReporting}
                color='primary'>
                Save Outbound Reporting
              </Button>
            </div>
          )}
        </CardBody>
      </Card>
    )
  );
}
