import moment from 'moment';

export const utcToLocal = (utcDate, format) => {
  return moment.utc(utcDate).local().format(format);
};

export const REFUND_TYPES = {
  REGULAR: 'regular',
  INSURANCE: 'insurance',
};
export const REFUND_METHOD = Object.freeze({
  ACH: 7,
  CHECK: 5,
  GIFTCARD: 4,
  DONATION: 6,
});

export const TRANSACTION_STATUSES = Object.freeze({
  PENDING: 'Pending',
  SENT_UNCASHED: 'Sent-uncashed',
  ACH_SENT: 'ACH-sent',
  SUCCESS: 'Success',
  ACH_RETURNED: 'ACH-returned',
  VOIDED: 'Voided',
  ERROR: 'Error',
});

export const BUSINESS_UNITS_FEATURE_FLAG = 'facility-business-units';
export const NEW_DATA_MAPPING_CONFIG_FEATURE_FLAG = 'new-data-mapping-config';
